import { NumberInputProps as MNumberInputProps } from "@mantine/core";

export const parser = (thousandSeparator: string): MNumberInputProps["parser"] =>
    thousandSeparator ? (value) => value?.replace(new RegExp(`\\${thousandSeparator}`, "g"), "") : undefined;

export const formatter = (thousandSeparator: string): MNumberInputProps["formatter"] =>
    thousandSeparator
        ? (value = "") =>
              !Number.isNaN(parseFloat(value)) ? value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator) : ""
        : undefined;
export const formatThousandNumberWithSpaces = (num: number | undefined): string => {
    if (num === undefined) {
        return "0";
    }
    return new Intl.NumberFormat("ru-RU").format(num);
};
