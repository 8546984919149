import { Box, Collapse, Flex, Group, Skeleton } from "@mantine/core";
import FooterMenuGroup from "entities/footer/ui/FooterMenuGroup";
import PersonalDataAccess from "features/footer/ui/PersonalDataAccess";
import Subscribe from "features/footer/ui/Subscribe";
import { IconLogo } from "public/icons/IconLogo";
import Typography from "shared/ui/Typography/Typography";
import { useSmallerThanMedia } from "shared/utils/mediaQuery";
import { useGetMenu } from "widgets/footer/api/menuItems";

export function FooterContent() {
    const { menu, isLoading } = useGetMenu();
    const isLargerThanMd = useSmallerThanMedia("md");

    return (
        <>
            <Collapse in={isLoading}>
                <Flex gap={20}>
                    {Array.from(Array(7).keys()).map((_, i) => (
                        <Skeleton w={200} h={180} key={i} />
                    ))}
                </Flex>
            </Collapse>
            <Collapse in={!isLoading}>
                <Group spacing={65} position="apart" align="flex-start">
                    <Flex
                        direction={{ base: "column", sm: "row" }}
                        align={{ base: "start", sm: "end", md: "flex-start" }}
                        justify="space-between"
                        w="100%"
                        gap="50px">
                        <nav style={{ width: "100%" }}>
                            <Flex
                                gap={{ base: "10px", md: "65px" }}
                                align="flex-start"
                                wrap="wrap"
                                justify="space-between"
                                direction={{ base: "column", md: "row" }}>
                                {menu.map((item, index) => (
                                    <div
                                        key={`${item.title}_${index}`}
                                        style={{
                                            width: isLargerThanMd ? "100%" : "fit-content",
                                            marginTop: index === menu.length - 1 && isLargerThanMd ? "50px" : undefined,
                                        }}>
                                        <Flex
                                            maw={{ base: "100%", sm: "100%", md: "fit-content" }}
                                            justify={{ base: "space-between", sm: "space-between", md: "unset" }}>
                                            <FooterMenuGroup {...item} />
                                        </Flex>
                                    </div>
                                ))}
                            </Flex>

                            <Flex direction="column" mt={15} display={{ base: "flex", md: "none" }} gap="15px">
                                <Typography tag="osRegular">115093, г.Москва, ул. Люсиновская д.53, корп.2</Typography>
                                <Typography tag="osRegular" noWrap>
                                    <a style={{ textDecoration: "none", color: "black" }} href="tel:74955653377">
                                        +7(495) 565-33-77
                                    </a>
                                </Typography>
                                <Typography tag="osRegular">info@vivagifts.ru</Typography>
                            </Flex>
                        </nav>
                        <Subscribe />
                    </Flex>
                </Group>
            </Collapse>
            <Group mt={{ base: "50px", sm: "100px" }} position="apart">
                <Flex
                    align={{ base: "start", md: "center" }}
                    justify="space-between"
                    w="100%"
                    direction={{ base: "column-reverse", md: "row" }}
                    gap={{ base: "50px", md: "0px" }}>
                    <Flex
                        maw="100%"
                        align={{ base: "start", sm: "center", md: "center" }}
                        gap="30px"
                        direction={{ base: "column", sm: "row", md: "row" }}>
                        <IconLogo />
                        <Box maw="100%">
                            <Typography tag="osSmallRegular">
                                {"© 2010-2024 vivagifts — Сувенирная продукция с нанесением логотипа.\n" +
                                    "Все права защищены.\n"}
                            </Typography>
                        </Box>
                    </Flex>
                    <Box component="nav">
                        <PersonalDataAccess />
                    </Box>
                </Flex>
            </Group>
        </>
    );
}
