import { IconComponent } from "public/icons/types";

export const IconCircleUp: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        color={color}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M17.75 14.9722L12.375 9L7 14.9722"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
