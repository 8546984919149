import { ReactNode } from "react";
import { createStyles } from "@mantine/core";
import { montserrat } from "providers/Theme/fonts";
import { CardShadow } from "shared/styles/boxShadow";

export const useDefaultInputStyles = createStyles(
    (theme, { floating, icon }: { floating: boolean; icon?: ReactNode }) => ({
        root: {
            position: "relative",
            color: theme.colors.main_Black[0],
            ":hover": {
                label: {
                    color: theme.colors.main_MainDarkGray[0],
                },
            },
        },
        label: {
            position: "absolute",
            zIndex: 2,
            top: 12,
            left: icon ? "44px" : "12px",
            pointerEvents: "none",
            transition: "transform 150ms ease, color 150ms ease, font-size 150ms ease, padding 150ms ease",
            transform: floating ? `translate(0px, -8px)` : "none",
            fontSize: floating ? 12 : 14,
            color: floating ? theme.colors.main_MainDarkGray[0] : theme.colors.main_Black[0],
        },
        icon: {
            width: "44px",
            color: "inherit",
        },
        input: {
            height: 44,
            paddingTop: "19px",
            border: `1px solid ${theme.colors.main_Black[0]}`,
            borderRadius: "2px",
            ":hover, :focus": {
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
            },
            fontWeight: 500,
            color: "inherit",
        },
        withIcon: {
            paddingLeft: "44px",
        },
    })
);

export const useBottomLineStyles = createStyles((theme) => ({
    root: {},
    input: {
        textAlign: "center",
        fontFamily: montserrat.style.fontFamily,
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: 600,
        color: theme.colors.main_Black[0],
        minHeight: 26,
        height: 26,
        paddingTop: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 4,
        border: 0,
        borderBottom: `2px solid ${theme.colors.main_Black[0]}`,
        borderRadius: 0,
        "::placeholder": {
            fontFamily: montserrat.style.fontFamily,
            fontSize: 14,
            lineHeight: "20px",
            fontWeight: 600,
            color: theme.colors.main_MainLightGray[0],
        },
    },
}));

export const useSearchStyles = createStyles((theme) => ({
    root: {
        width: "100%",
    },
    icon: {
        justifyContent: "flex-start",
        color: theme.colors.main_Black[0],
    },
    input: {
        fontFamily: montserrat.style.fontFamily,
        fontSize: 14,
        lineHeight: 20,
        fontWeight: 500,
        color: theme.colors.main_Black[0],
        height: 44,
        border: 0,
        borderBottom: `1px solid ${theme.colors.main_Black[0]}`,
        ":focus": {
            borderBottom: `1px solid ${theme.colors.main_Black[0]}`,
        },
        borderRadius: 0,
        "::placeholder": {
            fontFamily: montserrat.style.fontFamily,
            fontSize: 14,
            lineHeight: 20,
            fontWeight: 500,
            color: theme.colors.main_Black[0],
        },
    },
}));

export const useTooltipStyles = createStyles((theme) => ({
    tooltip: {
        ...CardShadow,
        paddingBlock: 8,
        paddingInline: 12,
        background: theme.colors.second_white[0],
        color: theme.colors.main_Black,
        fontFamily: montserrat.style.fontFamily,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "24px",
        borderRadius: 4,
        width: "max-content",
        whiteSpace: "normal",
    },
}));
