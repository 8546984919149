import { FC, memo } from "react";
import { Breadcrumbs, MediaQuery, Skeleton } from "@mantine/core";
import { LinkProps } from "next/link";
import { IconCircleDown } from "public/icons/IconCircleDown";
import Typography from "shared/ui/Typography/Typography";
import { useSmallerThanMedia } from "shared/utils/mediaQuery";
import { Color } from "styles/index";
import { StyledLink } from "../Link";

export interface BreadCrumbsProps {
    list?: { href?: LinkProps["href"]; displayName: string; skeleton?: boolean }[];
}

const truncateStyle = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    color: Color.main_Black,
};

export const BreadCrumbs: FC<BreadCrumbsProps> = memo(({ list = [] }) => {
    const isLargerThanSm = useSmallerThanMedia("sm");

    if (!list.length) return <></>;

    const mobileItems = isLargerThanSm
        ? [list[0], { displayName: "...", skeleton: false }, list[list.length - 1]]
        : list;

    return (
        <MediaQuery query="print" styles={{ display: "none" }}>
            <Breadcrumbs
                aria-label="breadcrumb"
                mt={11}
                separator={<IconCircleDown size={8} style={{ transform: "rotate(-90deg)" }} />}>
                {mobileItems.map((item) => {
                    if (item.skeleton) {
                        return <Skeleton key={item.displayName} h={20} w={200} />;
                    }
                    if (item.href) {
                        return (
                            <StyledLink href={item.href} key={item.displayName}>
                                <Typography tag="osSmallRegular" style={truncateStyle}>
                                    {item.displayName}
                                </Typography>
                            </StyledLink>
                        );
                    }
                    return (
                        <Typography tag="osSmallRegular" key={item.displayName} style={truncateStyle}>
                            {item.displayName}
                        </Typography>
                    );
                })}
            </Breadcrumbs>
        </MediaQuery>
    );
});

BreadCrumbs.displayName = "BreadCrumbs";
