import { memo } from "react";
import { IconCircleUp } from "public/icons/IconCircleUp";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";
import { Color } from "styles/index";

interface Props {
    search: string;
}
export const ResultsLink = memo(function ResultsLink({ search }: Props) {
    return (
        <StyledLink
            href={{
                pathname: `/search`,
                query: { search },
            }}
            style={{ justifyContent: "flex-start" }}>
            <Typography tag="mSemibold">Все результаты</Typography>
            <IconCircleUp color={Color.black} style={{ transform: "rotate(90deg)" }} />
        </StyledLink>
    );
});
