import { IconComponent } from "public/icons/types";

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;

export const IconCircleDown: IconComponent = ({ size = 24, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        color={color}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M17.75 9L12.375 14.9722L7 9"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
