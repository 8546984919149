import { Carousel } from "@mantine/carousel";
import { Center } from "@mantine/core";
import { LinkProps } from "next/link";
import { IconCircleRight } from "public/icons/IconCircleRight";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";
import { Color } from "styles/index";

interface Props {
    text: string;
    href?: LinkProps["href"];
}

export const Slide: React.FC<Props> = ({ text, href }) => {
    const innerContent = (
        <Center h={40}>
            <Typography tag="mCaps" color="white">
                {text}
            </Typography>
            {!!href && (
                <Center ml={15}>
                    <IconCircleRight color={Color.white} />
                </Center>
            )}
        </Center>
    );
    return <Carousel.Slide>{href ? <StyledLink href={href}>{innerContent}</StyledLink> : innerContent}</Carousel.Slide>;
};
