import React from "react";
import { Center } from "@mantine/core";
import { BaseSwitch, BaseSwitchProps } from "../BaseSwitch";

export interface StyledSwitchProps extends Omit<BaseSwitchProps, "onChange"> {
    label: string;
    onChange?: (newValue: boolean) => void;
}

export function StyledSwitch(props: StyledSwitchProps) {
    const { label, onChange, ...rest } = props;

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const value = event.target.checked;
        onChange && onChange(value);
    };

    return (
        <Center>
            <BaseSwitch {...rest} color="main_Black" onChange={handleChange} label={label} />
        </Center>
    );
}
