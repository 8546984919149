import React, { memo } from "react";
import { Checkbox, CheckboxProps } from "@mantine/core";

export interface BaseCheckboxProps extends Omit<CheckboxProps, "title"> {
    label?: string;
}

export const BaseCheckbox: React.FC<BaseCheckboxProps> = memo((props) => {
    return <Checkbox {...props} />;
});

BaseCheckbox.displayName = "BaseCheckbox";
