import { BaseApi } from "shared/api/baseApi";
import { $MenuItem, $PageItem, $PagesIndexRequest, PagesIndexRequest } from "shared/api/pages/types";
import { $AdaptedResponse } from "shared/api/types";
import { instance } from "src/application/config/axios";
import { z } from "zod";

export class PagesApi extends BaseApi {
    async indexMenus() {
        return $AdaptedResponse(z.array($MenuItem)).parse(await this.instance.get("/menus"));
    }
    async indexPages(request: PagesIndexRequest) {
        return $AdaptedResponse(z.array($PageItem)).parse(
            await this.instance.get("/pages", {
                params: $PagesIndexRequest.parse(request),
            })
        );
    }
    async showPages(id: number | string) {
        try {
            return $AdaptedResponse($PageItem).parse(await this.instance.get(`/pages/${id}`));
        } catch (error) {
            console.error("Ошибка при получении GET showPages:", error);
            throw error;
        }
    }
}

export const pagesApi = new PagesApi(instance);
