import { BaseApi } from "shared/api/baseApi";
import { $DetailedOffer, $Offer, $Product, AttributeParams, ProductsIndexRequest } from "shared/api/products/types";
import { $AdaptedPaginatedResponse, $AdaptedResponse } from "shared/api/types";
import { instance } from "src/application/config/axios";
import { z } from "zod";
import { $ProductOfferCalculate, ProductOfferCalculatePayload } from "../cart/types";

export class ProductsApi extends BaseApi {
    async calculateProductOffer(payload: ProductOfferCalculatePayload) {
        return $AdaptedResponse($ProductOfferCalculate).parse(
            await this.instance.post(`/product-offers/calculate`, payload)
        );
    }

    async searchProducts(request: ProductsIndexRequest) {
        return $AdaptedPaginatedResponse(z.array($Product)).parse(
            await this.instance.get("/v2/products/search", { params: request })
        );
    }

    async getProductsByCategory(request: ProductsIndexRequest, category: string | number) {
        try {
            return $AdaptedPaginatedResponse(z.array($Product)).parse(
                await this.instance.get(`/v2/products/category/${category}`, { params: request })
            );
        } catch (error) {
            console.error("Ошибка при получении getProductsByCategory:", error);
            throw error;
        }
    }
    async getOfferByAttributes(productId: number, request?: AttributeParams) {
        const attributes: { type: "color" | "variation"; id: string }[] = [];
        request?.colorId && attributes.push({ type: "color", id: request?.colorId });
        request?.variationId && attributes.push({ type: "variation", id: request?.variationId });
        return $AdaptedResponse($Offer).parse(
            await this.instance.post(`/v2/products/${productId}/offer-sku-attrs`, {
                attributes,
            })
        );
    }

    async getProductOffer(categoryAccessor: string, productAccessor: string, accessor: string) {
        try {
            const response = await this.instance.get(
                `/v2/product-offers/${categoryAccessor}/${productAccessor}/${accessor}`
            );
            return $AdaptedResponse($DetailedOffer).parse(response);
        } catch (error) {
            console.error("Ошибка при получении getProductOffer:", error);
            throw error;
        }
    }

    async getProductOfferByAttributes(productAccessor?: number | string, request?: AttributeParams) {
        const attributes: { type: "color" | "variation"; id: string }[] = [];
        request?.colorId && attributes.push({ type: "color", id: request?.colorId });
        request?.variationId && attributes.push({ type: "variation", id: request?.variationId });
        return $AdaptedResponse($DetailedOffer).parse(
            await this.instance.post(`/v2/product-offers/${productAccessor}/sku-attrs`, {
                attributes,
            })
        );
    }
}

export const productsApi = new ProductsApi(instance);
