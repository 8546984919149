import { memo } from "react";
import { Collapse, CollapseProps } from "@mantine/core";
import { Spinner } from "shared/ui/Spinner";

interface Props extends Omit<CollapseProps, "children"> {
    size?: number;
}

export const CollapseSpinner = memo(function CollapseSpinner({ size = 20, ...rest }: Props) {
    return (
        <Collapse {...rest}>
            <Spinner size={size} />
        </Collapse>
    );
});
