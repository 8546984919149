import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "shared/api/keys";
import { productsApi } from "shared/api/products/requests";
import { AttributeParams, Product, ProductsIndexRequest } from "shared/api/products/types";
import { useInfiniteRequest } from "shared/hooks/useInfiniteRequest";
import { ProductOfferCalculatePayload } from "../cart/types";

export const useProductSearch = (request: ProductsIndexRequest, enabled = true) => {
    return useQuery([QueryKeys.PRODUCTS_SEARCH, request], () => productsApi.searchProducts(request), {
        enabled: enabled,
    });
};

export const useProductByCategoryGet = (request: ProductsIndexRequest, category: string | number, enabled = true) => {
    return useQuery(
        [QueryKeys.PRODUCTS_BY_CATEGORY_GET, category, request],
        () => productsApi.getProductsByCategory(request, category),
        {
            enabled: enabled,
            keepPreviousData: true,
            staleTime: 600000,
            cacheTime: 600000,
        }
    );
};

export const useProductByCategoryGetInfinite = (
    request: ProductsIndexRequest,
    category: string | number,
    enabled = true
) => {
    return useInfiniteRequest<Product>(
        [QueryKeys.PRODUCTS_BY_CATEGORY_GET_INFINITE, category, request],
        (data) => productsApi.getProductsByCategory({ ...request, page: data.pageParam }, category),
        {
            enabled: enabled,
            keepPreviousData: true,
            staleTime: 60000,
            cacheTime: 60000,
        }
    );
};

export const useProductSearchInfinite = (request: ProductsIndexRequest, enabled = true) => {
    return useInfiniteRequest<Product>(
        [QueryKeys.PRODUCTS_INDEX_INFINITE, request],
        (data) => productsApi.searchProducts({ ...request, page: data.pageParam }),
        {
            enabled: enabled,
        }
    );
};

export const useShowOffer = (
    {
        accessor,
        categoryAccessor,
        productAccessor,
    }: { categoryAccessor: string; accessor: string; productAccessor: string },
    params?: AttributeParams
) => {
    const hasParams = !!params?.colorId || !!params?.variationId;
    const queryKey = hasParams
        ? [QueryKeys.PRODUCTS_OFFER_SHOW, categoryAccessor, productAccessor, params]
        : [QueryKeys.PRODUCTS_OFFER_SHOW, categoryAccessor, productAccessor, accessor];

    return useQuery(
        queryKey,
        () =>
            !!params?.colorId || !!params?.variationId
                ? productsApi.getProductOfferByAttributes(productAccessor, params)
                : productsApi.getProductOffer(categoryAccessor, productAccessor, accessor),
        {
            enabled:
                !!accessor?.toString().trim() &&
                !!productAccessor?.toString().trim() &&
                !!categoryAccessor?.toString().trim(),
            staleTime: 600000,
            cacheTime: 600000,
            keepPreviousData: true,
        }
    );
};

export const useCalculateOffer = () => {
    return useMutation([QueryKeys.PRODUCTS_OFFER_SHOW], (payload: ProductOfferCalculatePayload) =>
        productsApi.calculateProductOffer(payload)
    );
};

export const useOfferByAttributes = (productId: number, params?: AttributeParams) => {
    return useQuery(
        [QueryKeys.PRODUCTS_OFFER_INDEX, params, productId],
        () => productsApi.getOfferByAttributes(productId, params),
        {
            enabled: !!params?.colorId || !!params?.variationId,
            staleTime: 60000,
            cacheTime: 60000,
            keepPreviousData: true,
        }
    );
};
