import { createStyles } from "@mantine/core";

export const useBasketCartItemStyles = createStyles(() => ({
    price: {
        fontWeight: 700,
        fontSize: 12,
        lineHeight: "16px",
    },
    quantity: {
        fontWeight: 700,
        fontSize: 12,
        lineHeight: "16px",
    },
}));
