import React from "react";
import { Switch, SwitchProps } from "@mantine/core";
import Typography from "shared/ui/Typography/Typography";

export interface BaseSwitchProps extends SwitchProps {
    label?: string;
}

export const BaseSwitch: React.FC<BaseSwitchProps> = ({ label, ...props }) => {
    return <Switch {...props} label={<Typography>{label}</Typography>} />;
};
