import { memo } from "react";
import { Flex, Stack, UnstyledButton } from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";
import { ModalSettings } from "@mantine/modals/lib/context";
import { IconX } from "public/icons/IconX";
import Typography from "shared/ui/Typography/Typography";
import { Color } from "styles/index";
import { getStyles } from "./utils";

const ModalHeader = memo(function ModalHeader({ title }: { title: string }): JSX.Element {
    return (
        <Stack spacing={10} mb={30}>
            <Flex justify="flex-end">
                <UnstyledButton onClick={() => closeAllModals()} style={{ height: 32 }}>
                    <IconX color={Color.main_Black} size={32} />
                </UnstyledButton>
            </Flex>
            <Typography tag="h2">{title}</Typography>
        </Stack>
    );
});
export interface ModalProviderSettings extends Omit<ModalSettings, "withCloseButton"> {
    title: string;
}
interface Options {
    wide?: boolean;
}
/**
 * Вызов стилизованной модалки
 * @param options для DRY при дизайнерских
 */
export const openDefaultModal = (
    { className, children, title, ...settings }: ModalProviderSettings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options?: Options
) => {
    return openModal({
        className,
        styles: (theme) => getStyles(theme, { wide: options?.wide }),
        closeOnClickOutside: false,
        withCloseButton: false,
        children: (
            <>
                <ModalHeader title={title} />
                {children}
            </>
        ),
        ...settings,
    });
};
