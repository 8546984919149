import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "shared/api/keys";
import { pagesApi } from "shared/api/pages/requests";

export const useMenusIndex = () => {
    return useQuery([QueryKeys.MENUS_INDEX], () => pagesApi.indexMenus(), {
        cacheTime: 360000,
        staleTime: 36000,
    });
};
