import { Center, Flex } from "@mantine/core";
import { IconLogo } from "public/icons/IconLogo";
import { IconMagnifyingGlass } from "public/icons/IconMagnifyingGlass";
import NoSsr from "shared/components/NoSsr";
import { StyledLink } from "shared/ui/Link";
import { Color } from "styles/index";
import { BasketButton } from "../BasketButton";
import { BasketHoverCard } from "../BasketHoverCard";
import { SearchButton } from "../SearchButton";
import { ToggleMenu } from "./ToggleMenu";

export function HeaderContent() {
    return (
        <Flex align="center" justify="space-between">
            <ToggleMenu />
            <Center>
                <StyledLink href="/">
                    <IconLogo color={Color.black} />
                </StyledLink>
            </Center>
            <Flex p={0} align="center" gap={20}>
                <NoSsr placeholder={<IconMagnifyingGlass width={20} />}>
                    <SearchButton />
                </NoSsr>
                <BasketHoverCard>
                    <BasketButton />
                </BasketHoverCard>
            </Flex>
        </Flex>
    );
}
