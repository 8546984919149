import { useState } from "react";
import { Burger, Drawer } from "@mantine/core";
import { MenuContent } from "./MenuContent";

export function ToggleMenu() {
    const [opened, setOpened] = useState(false);

    const toggleDrawer = () => {
        setOpened((prev) => !prev);
    };

    return (
        <>
            <Burger opened={opened} onClick={toggleDrawer} />
            <Drawer
                size="100%"
                opened={opened}
                onClose={toggleDrawer}
                styles={{ drawer: { display: "flex", flexDirection: "column", alignItems: "start" } }}>
                <MenuContent />
            </Drawer>
        </>
    );
}
