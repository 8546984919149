import React, { forwardRef, memo } from "react";
import ReactInputMask from "react-input-mask";
import { Input, TextInputProps, useInputProps } from "@mantine/core";

export interface BaseInputProps extends Omit<TextInputProps, "title"> {
    mask?: string;
}

export const BaseInput = memo(
    forwardRef<HTMLDivElement, BaseInputProps>(({ mask = "+7 (999) 999-99-99", ...props }, ref) => {
        // https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/TextInput/TextInput.tsx
        // https://v5.mantine.dev/core/input/#custom-component
        const { inputProps, wrapperProps, ...others } = useInputProps("TextInput", {}, props);
        return (
            <Input.Wrapper ref={ref} {...wrapperProps}>
                {props.type == "mask" ? (
                    <Input {...inputProps} {...others} component={ReactInputMask} mask={mask} />
                ) : (
                    <Input {...inputProps} {...others} />
                )}
            </Input.Wrapper>
        );
    })
);
BaseInput.displayName = "BaseInput";
