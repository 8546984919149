import React, { memo } from "react";
import { createStyles } from "@mantine/core";
import { montserrat } from "providers/Theme/fonts";
import { BaseCheckbox, BaseCheckboxProps } from "../BaseCheckbox";

export interface StyledCheckboxProps extends BaseCheckboxProps {
    className?: string;
}

const useStyles = createStyles((theme) => ({
    label: {
        paddingLeft: "10px",
        fontFamily: montserrat.style.fontFamily,
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
    },
    input: {
        border: `2px solid ${theme.colors.main_Black[0]}`,
        borderRadius: "2px",
        cursor: "pointer",
    },
}));

export const StyledCheckbox: React.FC<StyledCheckboxProps> = memo(({ className, ...props }) => {
    const { classes } = useStyles();
    return (
        <div className={className}>
            <BaseCheckbox classNames={classes} {...props} />
        </div>
    );
});

StyledCheckbox.displayName = "StyledCheckbox";
