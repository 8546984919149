import React from "react";
import { Center } from "@mantine/core";
import { Spinner } from "../Spinner";

interface Props {
    size?: number;
}

const Loader: React.FC<Props> = ({ size = 150 }) => {
    return (
        <Center mih="100%" miw="100%">
            <Spinner size={size} />
        </Center>
    );
};

export default Loader;
