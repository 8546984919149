import { useMutation } from "@tanstack/react-query";
import { MutationKeys } from "shared/api/keys";
import { usersApi } from "../../requests";
import { UpdatePasswordRequest } from "../../types";

export const useUserUpdatePassword = () => {
    return useMutation([MutationKeys.USER_PASSWORD_UPDATE], (data: UpdatePasswordRequest) => {
        return usersApi.updateUserPassword(data);
    });
};
