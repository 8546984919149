import { useState } from "react";
import BaseTextarea, { BaseTextareaProps } from "shared/ui/Textarea/BaseTextarea";
import { useStyles } from "shared/ui/Textarea/StyledTextarea/styles";

export type StyledTextareaProps = BaseTextareaProps;
function StyledTextarea({ onBlur, onFocus, ...props }: StyledTextareaProps): JSX.Element {
    const [focused, setFocused] = useState(false);
    const isFloating = !!String(props.value).trim() || focused;

    const { classes } = useStyles({ icon: !!props.icon, floating: isFloating });
    return (
        <BaseTextarea
            classNames={classes}
            onFocus={(event) => {
                onFocus?.(event);
                setFocused(true);
            }}
            onBlur={(event) => {
                onBlur?.(event);
                setFocused(false);
            }}
            {...props}
        />
    );
}
export default StyledTextarea;
