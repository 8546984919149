import { Select, SelectItem, SelectProps, Stack, StackProps } from "@mantine/core";

export type TOption = SelectItem;

export interface BaseSelectProps extends Omit<SelectProps, "data" | "title" | "variant"> {
    //меняем название data на options чтобы придерживаться одного нейминга
    options: SelectProps["data"];
    wrapperProps?: Omit<StackProps, "children">;
}

export const BaseSelect = ({ options, wrapperProps, ...props }: BaseSelectProps) => {
    return (
        //TODO: Я не стал его выпиливать тк можно где-то в другом месте сломать
        <Stack {...wrapperProps}>
            <Select {...props} defaultValue={props.value} data={options} />
        </Stack>
    );
};
