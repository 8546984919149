import { BaseApi } from "shared/api/baseApi";
import { $AdaptedResponse } from "shared/api/types";
import { instance } from "src/application/config/axios";
import { $CategoriesResponse, $CategoryShow, CategoriesRequest, CategoriesResponse } from "./types";

class CategoriesApi extends BaseApi {
    async indexCategories(params: CategoriesRequest): Promise<CategoriesResponse> {
        try {
            return $CategoriesResponse.parse(await this.instance.get("categories", { params }));
        } catch (error) {
            console.error("Ошибка при получении indexCategories:", error);
            throw error;
        }
    }
    async showCategories(slug: string | number) {
        try {
            return $AdaptedResponse($CategoryShow).parse(await this.instance.get(`/categories/${slug}`));
        } catch (error) {
            console.error("Ошибка при получении showCategories:", error);
            throw error;
        }
    }
}

export const categoriesApi = new CategoriesApi(instance);
