import { Flex } from "@mantine/core";
import { SlugStaticPage } from "shared/api/pages/types";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";
import { AuthSection } from "../AuthSection";
import { CategoriesSection } from "../CategoriesSection";

export function MenuContent() {
    return (
        <Flex direction="column" gap="30px" p="30px">
            <CategoriesSection />
            <AuthSection />
            <Flex direction="column" align="start" gap="10px">
                <Typography tag="mSemibold">Москва</Typography>
                <StyledLink href={`/page/${SlugStaticPage.ABOUT_COMPANY}`}>
                    <Typography tag="mSemibold">Компания</Typography>
                </StyledLink>
                <Typography tag="mSemibold">Нанесения</Typography>

                <StyledLink href="/contacts" isExternalLink={false}>
                    <Typography tag="mSemibold">Контакты</Typography>
                </StyledLink>
            </Flex>
            <Flex direction="column">
                <Typography tag="osSemibold" noWrap>
                    <a style={{ textDecoration: "none", color: "black" }} href="tel:74955653377">
                        +7(495) 565-33-77
                    </a>
                </Typography>
                <Typography tag="osRegular">пн-пт: с 10 до 18</Typography>
            </Flex>
        </Flex>
    );
}
