export const cartTableSizes = {
    columnsWidth: {
        image: 70,
        name: 275,
        count: 90,
        amountProduct: 120,
        drawing: 100,
        withoutDiscount: 100,
        amount: 100,
        remove: 20,
    },
    gap: 30,
};

export const sumColumnsCartTable = (columns: (keyof typeof cartTableSizes.columnsWidth)[]) => {
    if (columns.length == 0) return 0;

    const sizes = columns.map((item) => cartTableSizes.columnsWidth[item]);
    const gaps = cartTableSizes.gap * (sizes.length - 1);
    return sizes.reduce((prev, current) => prev + current, 0) + gaps;
};
