import { Box, Header as MantineHeader, MediaQuery } from "@mantine/core";
import { CategoriesSection } from "features/header/ui/CategoriesSection";
import { HeaderContent } from "features/header/ui/components/HeaderContent";
import { TopSection } from "features/header/ui/TopSection";

export function Header() {
    const headerStyles = {
        height: "auto",
        withBorder: false,
        pt: 25,
        sx: { zIndex: 102 },
    };

    return (
        <>
            <MediaQuery smallerThan="md" query="print" styles={{ display: "none" }}>
                <MantineHeader {...headerStyles}>
                    <TopSection />
                    <Box mt={25}>
                        <CategoriesSection />
                    </Box>
                </MantineHeader>
            </MediaQuery>
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
                <MantineHeader {...headerStyles}>
                    <HeaderContent />
                </MantineHeader>
            </MediaQuery>
        </>
    );
}
