import { useEffect } from "react";
import { useIsomorphicEffect, useWindowScroll } from "@mantine/hooks";

export const ScrollRestoration = () => {
    const [scroll, scrollTo] = useWindowScroll();
    const KEY = "scrollPosition";

    useIsomorphicEffect(() => {
        const scrollPosition = sessionStorage.getItem(KEY);

        if (scrollPosition) {
            setTimeout(() => scrollTo({ y: Number(scrollPosition) }), 500);
            sessionStorage.removeItem(KEY);
        }
    }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.setItem(KEY, String(scroll.y));
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [scroll.y]);

    return null;
};
