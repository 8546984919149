import { useCallback } from "react";
import { IFooterMenuGroup, IFooterMenuItem } from "entities/footer/types";
import { openFeedbackModal } from "features/footer/ui/FeedbackModal";
import useCategories from "shared/api/categories/hooks/queries/useCategories";
import { CategoriesData } from "shared/api/categories/types";
import { useMenusIndex } from "shared/api/pages/hooks/useMenusIndex";
import { MenuItem, PageItem } from "shared/api/pages/types";

const getMenuFromGlossaries = (glossaries: CategoriesData): IFooterMenuGroup["content"][number] => {
    return {
        name: glossaries.name,
        isExternalLink: false,
        href: { pathname: "/catalog/[categoryId]", query: { categoryId: glossaries.slug ?? glossaries.id.toString() } },
    };
};

const adaptPageItem = (data: PageItem): IFooterMenuItem<false> => {
    return {
        name: data.title,
        href: { pathname: "/page/[slug]", query: { slug: data.slug ?? data.id.toString() } },
        isExternalLink: false,
    };
};
const adaptMenu = (data: MenuItem): IFooterMenuGroup => {
    return {
        title: data.title,
        content: data.pages.map(adaptPageItem),
    };
};
export const useGetMenu = (): { menu: IFooterMenuGroup[]; isLoading: boolean } => {
    const { data, isLoading } = useCategories();
    const apiMenuContent = data?.data.map(getMenuFromGlossaries) ?? [];
    const { data: menusRequest, isLoading: isMenuLoading } = useMenusIndex();
    const staticPages = (menusRequest ?? [])
        .map(adaptMenu)
        .filter((item) => !!item.content.length)
        .filter((item) => item.title !== "Информация")
        .filter((item) => item.title !== "Помощь");

    const formatLinks = useCallback(
        (groupTitle: string) => {
            return (
                menusRequest
                    ?.find((item) => item.title === groupTitle)
                    ?.pages.map((item) => ({ name: item.title, href: "/page/" + item.slug, isExternalLink: false })) ??
                []
            );
        },
        [menusRequest]
    );

    const dynamicInformation = formatLinks("Информация")
        .filter((item) => item.name !== "Согласие на обработку персональных данных")
        .filter((item) => item.name !== "Политика обработки персональных данных");
    const dynamicHelp = formatLinks("Помощь");

    const staticInformation: IFooterMenuItem<false>[] = [
        {
            name: "Новости",
            isExternalLink: false,
            href: "/news",
        },
        {
            name: "Контакты",
            isExternalLink: false,
            href: "/contacts",
        },
        {
            name: "Вакансии",
            isExternalLink: false,
            href: "/vacancies",
        },
    ];

    const information: IFooterMenuGroup = {
        title: "Информация",
        content: [...staticInformation, ...dynamicInformation] as IFooterMenuGroup["content"],
    };

    const staticHelp = [
        {
            name: "Вопросы и ответы",
            isExternalLink: false,
            href: "/faq",
        },
        {
            name: "Написать директору",
            onClick: () => openFeedbackModal(),
        },
    ];

    const help: IFooterMenuGroup = {
        title: "Помощь",
        content: [...staticHelp, ...dynamicHelp] as IFooterMenuGroup["content"],
    };

    const staticMenu: IFooterMenuGroup[] = [information, help];

    const hasCatalogMenu = apiMenuContent.length > 0;

    return {
        menu: [
            ...(hasCatalogMenu
                ? [
                      {
                          title: "Каталог",
                          content: apiMenuContent,
                      },
                  ]
                : []),
            ...staticPages,
            ...staticMenu,
        ],
        isLoading: isLoading || isMenuLoading,
    };
};
