import { useMemo } from "react";
import { Box, Center, Flex } from "@mantine/core";
import { Route } from "nextjs-routes";
import { IconLogo } from "public/icons/IconLogo";
import { IconMagnifyingGlass } from "public/icons/IconMagnifyingGlass";
import { SlugStaticPage } from "shared/api/pages/types";
import { HoverMenu } from "shared/components/HoverMenu";
import NoSsr from "shared/components/NoSsr";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";
import { Color } from "src/application/styles";
import { useGetMenu } from "widgets/footer/api/menuItems";
import { AuthSection } from "../AuthSection";
import { BasketButton } from "../BasketButton";
import { BasketHoverCard } from "../BasketHoverCard";
import { SearchButton } from "../SearchButton";

export function TopSection() {
    const { menu = [] } = useGetMenu();

    const printingMethods = useMemo(() => {
        const contacts = menu.find((item) => item.title === "Нанесение логотипа")?.content;
        //Места нанесения это то, что приходит в меню с title ==== "Нанесение логотипа"
        return contacts?.map((item) => ({
            href: item.href as Route,
            id: item.name,
            text: item.name,
        }));
    }, [menu]);

    const hasPrintingMethods = useMemo(() => {
        return printingMethods?.length && printingMethods?.length > 0;
    }, [menu, printingMethods]);

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
            }}>
            <Flex align="center" gap={50}>
                <Typography tag="mSemibold">Москва</Typography>
                <StyledLink href={`/page/${SlugStaticPage.ABOUT_COMPANY}`}>
                    <Typography tag="mSemibold">Компания</Typography>
                </StyledLink>
                {hasPrintingMethods && (
                    <HoverMenu label="Нанесения" options={printingMethods} withArrow withCursorPointer />
                )}
                <Flex direction="column">
                    <Typography tag="osSemibold" noWrap>
                        <a style={{ textDecoration: "none", color: "black" }} href="tel:74955653377">
                            +7(495) 565-33-77
                        </a>
                    </Typography>
                    <Typography tag="osRegular">пн-пт: с 10 до 18</Typography>
                </Flex>
            </Flex>
            <Center>
                <StyledLink href="/">
                    <IconLogo color={Color.black} />
                </StyledLink>
            </Center>
            <Flex align="center" gap={50} justify="end">
                <StyledLink href="/contacts" isExternalLink={false}>
                    <Typography tag="mSemibold">Контакты</Typography>
                </StyledLink>
                <Flex p={0} align="center" gap={20}>
                    <NoSsr placeholder={<IconMagnifyingGlass width={20} />}>
                        <SearchButton />
                    </NoSsr>
                    <BasketHoverCard>
                        <BasketButton />
                    </BasketHoverCard>
                </Flex>
                <AuthSection />
            </Flex>
        </Box>
    );
}
