import { IconComponent } from "public/icons/types";

export const IconMinusCircle: IconComponent = ({ size = 24, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        color={color}
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M8 12H16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
