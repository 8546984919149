import { IconComponent } from "public/icons/types";

export const IconMarkCheckS: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        color={color}
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M6.66663 10L9.16663 12.5L14.1666 7.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
