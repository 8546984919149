import NoSsr from "shared/components/NoSsr";
import { Button } from "shared/ui/Forms/Styled";

export const ButtonToLegacySite = () => (
    <NoSsr>
        <Button
            
            label="Перейти на старую версию сайта"
            mt={30}
            href="https://vivagifts.ru/"
            isExternalLink
            sx={{
                pointerEvents: "auto",
                position: "fixed",
                bottom: 50,
                right: 50,
                cursor: "pointer",
                zIndex: 1100,
            }}
        />
    </NoSsr>
);
