import { IconComponent } from "public/icons/types";

export const IconX: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        color={color}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path d="M18.75 5.25L5.25 18.75" strokeWidth="2" strokeLinecap="round" />
        <path d="M18.75 18.75L5.25 5.25" strokeWidth="2" strokeLinecap="round" />
    </svg>
);
