import { IconComponent } from "public/icons/types";

export const IconBuildings: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        color={color}
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path d="M1.5 20.2484H22.5" strokeWidth="2" />
        <path d="M13.499 20.2484V2.99841H2.99902V20.2484" strokeWidth="2" />
        <path d="M20.999 20.2484V8.99841H13.499" strokeWidth="2" />
        <path d="M5.99902 6.74841H8.99902" strokeWidth="2" />
        <path d="M7.49902 12.7484H10.499" strokeWidth="2" />
        <path d="M5.99902 16.4984H8.99902" strokeWidth="2" />
        <path d="M16.499 16.4984H17.999" strokeWidth="2" />
        <path d="M16.499 12.7484H17.999" strokeWidth="2" />
    </svg>
);
