import { CSSObject } from "@mantine/core";

export const CardShadow: CSSObject = {
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.16)",
};
export const InputShadow: CSSObject = {
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
};
export const DropShadow: CSSObject = {
    boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.06)",
};
