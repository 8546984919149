import React, { memo, ReactNode } from "react";
import { ButtonProps, UnstyledButton } from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils/lib/create-polymorphic-component/create-polymorphic-component";
import classNames from "classnames";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/navigation";
import { Spinner } from "../Spinner";
import classes from "./IconButton.module.scss";

export enum ButtonShape {
    default = "default",
    circle = "circle",
    noBorder = "noBorder",
}

interface TIconButtonProps extends Omit<PolymorphicComponentProps<"button", ButtonProps>, "size"> {
    icon: ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    to?: LinkProps["href"];
    size?: number;
    shape?: ButtonShape;
    disabled?: boolean;
    isLoading?: boolean;
}

export const IconButton = memo(
    ({
        icon,
        onClick,
        to,
        size = 32,
        shape = ButtonShape.noBorder,
        disabled,
        isLoading,
        ...props
    }: TIconButtonProps) => {
        const router = useRouter();

        const getHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (to) {
                router.push(to as string);
            }
            if (onClick) {
                onClick(event);
            }
        };

        return (
            <UnstyledButton
                {...props}
                style={{ width: size, height: size }}
                className={classNames(
                    classes.button,
                    { [classes.circle]: shape === "circle" },
                    { [classes.noBorder]: shape === "noBorder" }
                )}
                type="button"
                onClick={getHandleClick}
                disabled={disabled}>
                {isLoading ? (
                    <Spinner size={size} />
                ) : (
                    <div className={classes.iconContainer} style={{ width: size, height: size }}>
                        <span className={classes.icon}>{icon}</span>
                    </div>
                )}
                {to && (
                    <Link href={to}>
                        <a className={classes.link}></a>
                    </Link>
                )}
            </UnstyledButton>
        );
    }
);

IconButton.displayName = "IconButton";
