import { MantineTheme } from "@mantine/core";

export const getStyles = (theme: MantineTheme, { wide }: { wide?: boolean }) => ({
    modal: {
        boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.06)",
        background: theme.colors.second_white[0],
        borderRadius: 6,
        width: wide ? 800 : 500,
        padding: "30px 30px 50px 30px !important",
    },
    inner: {
        alignItems: "center",
    },
    overlay: {
        background: theme.colors.main_Black[0],
        opacity: 0.8,
    },
});
