import { AccordionStylesNames, createStyles, CSSObject } from "@mantine/core";
import { montserrat } from "providers/Theme/fonts";
import { CardShadow } from "shared/styles/boxShadow";

export const useAccordionStyles = createStyles<AccordionStylesNames | string>(
    (theme): Record<AccordionStylesNames | string, CSSObject> => ({
        item: {
            backgroundColor: theme.colors.second_SecondLightGray[0],
            transition: "background-color, box-shadow 150ms ease",
            "&[data-active]": {
                backgroundColor: theme.colors.second_white[0],
                borderColor: "transparent",
                ...CardShadow,
            },
        },
        control: {
            padding: "20px",
        },
        chevron: {
            marginLeft: "12px",
            minWidth: 20,
            width: 20,
            height: 20,
        },
        label: {
            fontFamily: montserrat.style.fontFamily,
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "20px",
            color: theme.colors.main_Black[0],
        },
        content: {
            padding: 20,
            paddingTop: 5,
        },
    })
);
