export enum TagType {
    /**
     * Montserrat / 600 / 48px / 56px
     */
    display = "h1",

    /**
     * Montserrat / 600 / 32px / 48px
     */
    h1 = "h1",
    /**
     * Montserrat / 600 / 24px / 32px
     */
    h2 = "h2",
    /**
     * Montserrat / 600 / 20px / 20px
     */
    h3 = "h3",
    /**
     * Montserrat / 600 / 16px / 20px
     */
    h4 = "h4",

    /**
     * Open Sans / 400 / 16px / 20px
     */
    osRegular = "p",
    /**
     * Open Sans / 600 / 16px / 20px
     */
    osSemibold = "p",
    /**
     * Open Sans / 400 / 14px / 20px
     */
    osSmallRegular = "p",
    /**
     * Open Sans / 600 / 14px / 20px
     */
    osSmallSemibold = "p",

    /**
     * Montserrat / 400 / 14px / 20px
     */
    mRegular = "p",
    /**
     * Montserrat / 500 / 14px / 20px
     */
    mMedium = "p",
    /**
     * Montserrat / 600 / 14px / 20px
     */
    mSemibold = "p",
    /**
     * Montserrat / 700 / 14px / 20px
     */
    mCaps = "p",
    /**
     * Montserrat / 700 / 13px / 16px
     */
    mSmallCaps = "p",

    /**
     * Montserrat / 600 / 14px / 20px
     */
    buttonSemibold = "p",
    /**
     * Montserrat / 700 / 14px / 20px
     */
    buttonCaps = "p",
    /**
     * Montserrat / 700 / 13px / 16px
     */
    buttonSmallCaps = "p",
    P = "p",
    osP = "p",
    /**
     * Montserrat / 400 / 11px / 14px
     */
    adviceText = "p",
}
