import dynamic from "next/dynamic";
import { openDefaultModal } from "shared/ui/ModalProvider/ModalProvider";

const LoginModal = dynamic(() => import("./LoginModal"), { loading: () => <>Авторизация</> });

export interface Values {
    email: string;
    password: string;
    rememberMe: boolean;
}

export const openLoginModal = () => {
    return openDefaultModal({
        title: "Авторизация",
        children: <LoginModal />,
    });
};

export default LoginModal;
