import { FC, memo } from "react";
import { Center, Loader } from "@mantine/core";
import { ColorsNames } from "providers/Theme/types";
import classes from "./Spinner.module.scss";

type TSpinnerProps = {
    color?: ColorsNames;
    size?: number;
};

export const Spinner: FC<TSpinnerProps> = memo(({ color = "main_Black", size = 40 }) => {
    return (
        <Center className={classes.spinner} style={{ width: size, height: size, display: "flex" }}>
            <Loader color={color} style={{ width: size, height: size }} />
        </Center>
    );
});
Spinner.displayName = "Spinner";
