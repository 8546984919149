import React, { forwardRef, memo, useMemo } from "react";
import { DefaultProps, Text, TextProps } from "@mantine/core";
import { openSans } from "providers/Theme/fonts";
import { TagType } from "shared/ui/Typography/types";
import { Color } from "styles/index";
import { useStylesTypography } from "./styles";

export type TypographyTag = keyof typeof TagType;
export type TypographyColor = keyof typeof Color | "inherit";
type DefaultTextProps = TextProps & Omit<React.HTMLProps<HTMLDivElement>, "ref"> & DefaultProps;
export interface TypographyProps extends Omit<DefaultTextProps, "color"> {
    tag?: TypographyTag;
    // чтобы убрать хардкод цвета - используйте null
    color?: TypographyColor | null;
    fontSize?: number;
    noWrap?: boolean;
    title?: string;
}

const Typography = forwardRef<HTMLDivElement, TypographyProps>(
    (
        {
            children,
            className,
            tag = "mRegular",
            color = "main_Black",
            fontSize,
            noWrap,
            title,
            ...props
        }: TypographyProps,
        ref
    ) => {
        const { classes } = useStylesTypography();

        const isOSFont = useMemo(() => tag.includes("os"), [tag]);

        const styles = useMemo(() => {
            if (!tag || tag == "P" || tag == "osP") return;
            return classes[tag];
        }, [tag]);

        return (
            <Text
                title={title}
                className={`${styles} ${className}`}
                component={TagType[tag]}
                ref={ref}
                style={{
                    ...(!!color && { color: Color[color] }),
                    ...(!!fontSize && { fontSize, lineHeight: `${fontSize}px` }),
                    ...(isOSFont && { fontFamily: openSans.style.fontFamily }),
                    ...(noWrap && { whiteSpace: "nowrap" }),
                }}
                {...props}>
                {children}
            </Text>
        );
    }
);

Typography.displayName = "Typography";
export default memo(Typography);
