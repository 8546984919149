export const getFileSize = (fileSize: number) => {
  if (fileSize < 1024) {
    return `${fileSize} b`;
  }
  if (fileSize >= 1024 && fileSize < 1048576) {
    fileSize /= 1024;
    if (!Number.isInteger(fileSize)) {
      return `${fileSize.toFixed(1)} kb`;
    }
    return `${fileSize} kb`;
  }

  if (fileSize >= 1048576 && fileSize < 1073741824) {
    fileSize /= 1048576;
    if (!Number.isInteger(fileSize)) {
      return `${fileSize.toFixed(1)} mb`;
    }
    return `${fileSize} mb`;
  }

  fileSize /= 1073741824;
  if (!Number.isInteger(fileSize)) {
    return `${fileSize.toFixed(1)} gb`;
  }
  return `${fileSize} gb`;
};
