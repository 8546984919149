import { Box } from "@mantine/core";
import { openThanksForSubscribeModal } from "features/footer/ui/ThanksForSubscribe";
import { FormikConfig } from "formik";
import { IconMessage } from "public/icons/IconMessage";
import { useSubscribe } from "shared/api/subscribe/hooks/queries/useSubscribe";
import { CardShadow } from "shared/styles/boxShadow";
import { Form } from "shared/ui/Form";
import { Button } from "shared/ui/Forms/Styled";
import { FInput } from "shared/ui/Input/FInput";
import Typography from "shared/ui/Typography/Typography";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

interface Values {
    email: string;
}
function Subscribe(): JSX.Element {
    const { mutate } = useSubscribe();
    const cfg: FormikConfig<Values> = {
        initialValues: {
            email: "",
        },
        validationSchema: toFormikValidationSchema(
            z.object({
                email: z.string({ required_error: "Обязательное поле" }).email("Некорректный email"),
            })
        ),
        onSubmit: ({ email }) => {
            mutate(
                { email, channel: "EMAIL_NEWS" },
                {
                    onSuccess: () => {
                        openThanksForSubscribeModal(email);
                    },
                }
            );
        },
    };

    return (
        <Box
            p={25}
            sx={() => ({
                borderRadius: 8,
                ...CardShadow,
            })}
            component="section"
            w={{ base: "100%", sm: "350px" }}
            miw={{ sm: "450px", md: "350px" }}
            mb={{ sm: "-50px" }}
            h="fit-content">
            <Form {...cfg}>
                <Typography tag="mCaps">Подпишитесь на рассылку</Typography>
                <FInput icon={<IconMessage />} label="Введите Email" name="email" mt={15} w="100%" />
                <Button label="Подписаться" variant="filled" mt={10} w="100%" type="submit" />
            </Form>
        </Box>
    );
}

export default Subscribe;
