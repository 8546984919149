import { IconComponent } from "public/icons/types";

export const IconPhone: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        color={color}
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M7.92 12.6662C8.7 14.2562 9.99 15.5362 11.58 16.3062C11.7 16.3662 11.83 16.3862 11.95 16.3762C12.08 16.3662 12.2 16.3262 12.31 16.2462L14.66 14.6762C14.76 14.6062 14.88 14.5662 15.01 14.5562C15.13 14.5462 15.26 14.5662 15.37 14.6162L19.76 16.4962C19.91 16.5562 20.03 16.6662 20.11 16.8062C20.19 16.9462 20.22 17.1062 20.2 17.2662C20.06 18.3562 19.56 19.2262 18.74 19.9462C17.92 20.6662 16.83 20.9462 15.74 20.9462C12.36 20.9462 9.12 19.8562 6.72 17.4662C4.32 15.0762 3 11.8462 3 8.46617C3 7.36617 3.4 6.31617 4.12 5.49617C4.84 4.67617 5.84 4.14617 6.93 4.00617C7.09 3.98617 7.25 4.01617 7.39 4.10617C7.53 4.18617 7.64 4.31617 7.7 4.45617L9.58 8.84617C9.63 8.95617 9.65 9.08617 9.64 9.20617C9.64 9.32617 9.6 9.44617 9.53 9.55617L7.97 11.9362C7.9 12.0462 7.86 12.1662 7.85 12.2962C7.84 12.4262 7.86 12.5562 7.92 12.6662Z"
            strokeWidth="2"
            strokeLinecap="square"
        />
    </svg>
);
