import { Collapse } from "@mantine/core";
import { CollapseSpinner } from "shared/ui/CollapseSpinner";
import Typography from "shared/ui/Typography/Typography";

export const SearchInfo = ({
    isNotFound,
    isSearching,
    isShortSearchValue,
}: {
    isNotFound?: boolean;
    isSearching?: boolean;
    isShortSearchValue?: boolean;
}) => {
    if (isNotFound) {
        return (
            <Collapse in={isNotFound}>
                <Typography mb={15}>Ничего не найдено</Typography>
            </Collapse>
        );
    }

    if (isShortSearchValue) {
        return <Typography>Для начала поиска введите от 4х символов</Typography>;
    }

    if (isSearching) {
        return <CollapseSpinner in={isSearching} size={35} />;
    }

    return null;
};
