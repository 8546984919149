import dynamic from "next/dynamic";
import { openDefaultModal } from "shared/ui/ModalProvider/ModalProvider";

const UpdatePasswordModal = dynamic(() => import("./UpdatePasswordModal"));

export const openUpdatePasswordModal = () => {
    return openDefaultModal({
        title: "Изменить пароль",
        children: <UpdatePasswordModal />,
    });
};
export default UpdatePasswordModal;
