import { IconComponent } from "public/icons/types";

export const IconShoppingCart: IconComponent = ({ size = 24, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        color={color}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <g clipPath="url(#clip0_349_3543)">
            <path
                d="M1 1H2.494C3.41174 1 4.2117 1.62459 4.43429 2.51493L7.11111 13.2222M7.11111 13.2222H18.994C19.9117 13.2222 20.7117 12.5976 20.9343 11.7073L23 3.44444H4.66667M7.11111 13.2222H5.88889C4.53886 13.2222 3.44444 14.3166 3.44444 15.6667V15.6667C3.44444 17.0167 4.53886 18.1111 5.88889 18.1111H23"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="9.55577"
                cy="20.5555"
                r="2.44444"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="19.3331"
                cy="20.5555"
                r="2.44444"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_349_3543">
                <rect width="24" height="24" fill="transparent" />
            </clipPath>
        </defs>
    </svg>
);
