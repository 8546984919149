import { IconComponent } from "public/icons/types";

export const IconUnvisible: IconComponent = ({ size = 24, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        color={color}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path d="M2.2002 2L22.2002 22" strokeWidth="2" strokeLinecap="square" />
        <path
            d="M20.7001 14.8C21.8001 13.3 22.5 12 22.5 12C22.5 12 18.9001 4 11.9001 4C11.3001 4 10.6001 4.1 10.1001 4.2"
            strokeWidth="2"
            strokeLinecap="square"
        />
        <path
            d="M6 6.29999C3.2 8.59999 1.5 12 1.5 12C1.5 12 5 20 12 20C14.3 20 16.2 19.1 17.8 18"
            strokeWidth="2"
            strokeLinecap="square"
        />
        <path
            d="M9.8002 10.2C9.4002 10.7 9.2002 11.3 9.2002 12C9.2002 13.7 10.5002 15 12.2002 15C12.9002 15 13.5002 14.8 14.0002 14.4"
            strokeWidth="2"
            strokeLinecap="square"
        />
    </svg>
);
