import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cartApi } from "shared/api/cart";
import { MutationKeys, QueryKeys } from "shared/api/keys";

export const useRemoveCartItem = () => {
    const queryClient = useQueryClient();
    return useMutation([MutationKeys.CART_ITEM_REMOVE], (id: number) => cartApi.removeCartItem(id), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.CART_INDEX]);
            queryClient.invalidateQueries([QueryKeys.CART_COUNT_SHOW]);
            queryClient.invalidateQueries([QueryKeys.PRODUCTS_BY_CATEGORY_GET_INFINITE])
        },
    });
};
