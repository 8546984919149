import { $RequiredEmail, $RequiredString } from "shared/types/validation";
import { z } from "zod";

export type FeedbackCreate = z.infer<typeof $FeedbackCreate>;
export const $FeedbackCreate = z.object({
    name: $RequiredString,
    phone: $RequiredString,
    email: $RequiredEmail,
    company: $RequiredString,
    message: $RequiredString,
});
