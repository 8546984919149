import { ReactNode } from "react";
import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme, { floating, icon }: { floating: boolean; icon?: ReactNode }) => ({
    root: {
        position: "relative",
        color: theme.colors.main_Black[0],
        ":hover": {
            label: {
                color: theme.colors.main_MainDarkGray[0],
            },
        },
    },
    label: {
        position: "absolute",
        zIndex: 2,
        top: 12,
        left: icon ? "44px" : "12px",
        pointerEvents: "none",
        transition: "transform 150ms ease, color 150ms ease, font-size 150ms ease, padding 150ms ease",
        transform: floating ? `translate(0px, -8px)` : "none",
        fontSize: floating ? 12 : 14,
        color: floating ? theme.colors.main_MainDarkGray[0] : theme.colors.main_Black[0],
    },
    icon: {
        width: "44px",
        color: "inherit",
    },
    wrapper: {
        height: "100%",
        border: `1px solid ${theme.colors.main_Black[0]}`,
        borderRadius: "2px",
        "&:has(.mantine-Textarea-invalid)": {
            borderColor: theme.colors.validation_red[0],
        },
    },
    input: {
        height: "100%",

        // в textarea при переполнении и появлении скролла неправильно работает paddingTop:
        // он остается вначале текста и уезжает вверх вместе с этим началом
        // из-за этого label накладывается на текст
        // данный костыль создан, чтобы решить эту проблему
        // paddingTop: "19px",
        paddingTop: 0,
        border: "none",
        borderTop: `19px solid ${theme.colors.second_white[0]}`,
        background: theme.colors.second_white[0],

        borderRadius: 0,
        ":hover, :focus": {
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
            borderColor: theme.colors.second_white[0],
        },
        fontWeight: 500,
        color: "inherit",
    },
    invalid: {
        borderColor: theme.colors.second_white[0],
    },
    withIcon: {
        paddingLeft: "44px",
    },
}));
