import React, { memo } from "react";
import { Collapse, Space } from "@mantine/core";
import { useField } from "formik";
import { StyledError } from "shared/ui/Error/StyledError";

export interface FErrorProps {
    name: string;
}

export const FError: React.FC<FErrorProps> = memo(({ name }) => {
    const [, meta] = useField(name);
    const hasError = !!meta.error && meta.touched;

    return (
        <Collapse in={hasError} key={name}>
            {!hasError && <Space h={20} />}
            <StyledError text={meta.error} />
        </Collapse>
    );
});

FError.displayName = "FError";
