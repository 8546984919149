import { IconComponent } from "public/icons/types";

export const IconDropdownDown: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        color={color}
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path d="M8 10L12 14L16 10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
