import { IconComponent } from "public/icons/types";

export const IconVisible: IconComponent = ({ size = 24, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        color={color}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M1.5 12C1.5 12 5 20 12 20C19 20 22.5 12 22.5 12C22.5 12 19 4 12 4C5 4 1.5 12 1.5 12Z"
            strokeWidth="2"
            strokeLinecap="square"
        />
        <path
            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
            strokeWidth="2"
            strokeLinecap="square"
        />
    </svg>
);
