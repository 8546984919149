import { $AdaptedResponse } from "shared/api/types";
import { z } from "zod";

export type BannerResponse = z.infer<typeof $BannerResponse>;
export type IBannerSlide = z.infer<typeof $BannerSlide>;

export const $BannerSlide = z.object({
    id: z.number(),
    content: z.string(),
    redirect_path: z.string().nullish(),
});

export const $BannerResponse = $AdaptedResponse(z.array($BannerSlide));
