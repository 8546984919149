import { Collapse, Flex, FlexProps } from "@mantine/core";
import CategoriesSectionSkeleton from "entities/header/CategoriesSectionSkeleton";
import { CategoryItem } from "entities/header/CategoryItem";
import useCategories from "shared/api/categories/hooks/queries/useCategories";
import { useLargerThanMedia } from "shared/utils/mediaQuery";

export function CategoriesSection() {
    const { data, isLoading } = useCategories();
    const isLargerThanMd = useLargerThanMedia("md", true);

    const categoryItems = data?.data.map((item) => <CategoryItem category={item} key={item.id} />);
    const flexProps: FlexProps = isLargerThanMd
        ? { justify: "center", maw: "calc(100vw - 57px)", wrap: "wrap" }
        : { direction: "column", align: "start" };

    return (
        <>
            <Collapse in={isLoading}>
                <CategoriesSectionSkeleton />
            </Collapse>
            <Collapse in={!isLoading}>
                <Flex {...flexProps}>{categoryItems}</Flex>
            </Collapse>
        </>
    );
}
