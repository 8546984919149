import { ReactNode } from "react";
import { IconMinusCircle } from "public/icons/IconMinusCircle";
import { IconPlusCircle } from "public/icons/IconPlusCircle";
import BaseAccordion, { BaseAccordionProps } from "shared/ui/Accordion/BaseAccordion";
import { useAccordionStyles } from "shared/ui/Accordion/StyledAccordion/styles";
import { Color } from "styles/index";

export type StyledAccordionProps = Omit<BaseAccordionProps, "classNames">;
const getChevron = (isOpen: boolean): ReactNode => {
    if (isOpen) {
        return <IconMinusCircle size={20} color={Color.main_Black} />;
    }
    return <IconPlusCircle size={20} color={Color.main_Black} />;
};
function StyledAccordion({ ...props }: StyledAccordionProps): JSX.Element {
    const { classes } = useAccordionStyles();
    return (
        <BaseAccordion
            classNames={classes}
            chevronPosition="right"
            variant="separated"
            radius={4}
            chevron={getChevron}
            disableChevronRotation={true}
            {...props}
        />
    );
}
export default StyledAccordion;
