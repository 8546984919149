import { LinkProps } from "next/link";

export const isUrl = (href: unknown): href is URL => {
    if (typeof href != "object") return false;
    try {
        new URL(href as URL);
        return true;
    } catch (e) {
        return false;
    }
};

export const getHref = (href: string | URL | LinkProps["href"]): LinkProps["href"] => {
    if (isUrl(href)) {
        return href.href as LinkProps["href"];
    }
    if (typeof href == "string") {
        return href as LinkProps["href"];
    }
    return href;
};
