import { createStyles, CSSObject, MantineTheme } from "@mantine/core";
import { ButtonVariant } from "shared/ui/Button/StyledButton/index";

const SidePadding = 24;
const ButtonHeight = 44;
const IconGap = 8;

const getStylesForType = (variant: ButtonVariant, theme: MantineTheme): CSSObject => {
    const disabledRules: CSSObject = {
        "&:disabled, &[data-loading]": {
            background: theme.colors.second_SecondLightGray[0],
            color: theme.colors.main_MainLightGray[0],
            borderColor: "transparent",
            "&::before": {
                content: "none",
            },
        },
        "&[data-loading]": {
            "& svg": {
                stroke: "currentcolor",
            },
        },
    };
    switch (variant) {
        case "filled":
            return {
                background: theme.colors.main_Black[0],
                color: theme.colors.second_white[0],
                "&:hover": {
                    background: theme.colors.main_MainDarkGray[0],
                },
                ...disabledRules,
            };
        case "light":
            return {
                background: theme.colors.second_SecondLightGray[0],
                color: theme.colors.main_Black[0],
                "&:hover": {
                    background: theme.colors.second_SecondDarkGray[0],
                    color: theme.colors.main_MainDarkGray[0],
                },
                ...disabledRules,
            };
        case "outline":
            return {
                background: "transparent",
                color: theme.colors.main_Black[0],
                "&:hover": {
                    borderColor: "transparent",
                    background: theme.colors.main_Black[0],
                    color: theme.colors.second_white[0],
                },
                ...disabledRules,
            };
        case "white":
            return {
                background: "transparent",
                color: theme.colors.main_Black[0],
                "&:hover": {
                    background: "transparent",
                    color: theme.colors.main_MainDarkGray[0],
                },
                ...disabledRules,
            };
        default:
            return {};
    }
};
const getStylesForIconOnly = (isIconOnly: boolean): CSSObject => {
    if (!isIconOnly) return {};
    return {
        paddingRight: 0,
        paddingLeft: 0,
        width: ButtonHeight,
    };
};

interface Arguments {
    isIconOnly: boolean;
    variant: ButtonVariant;
    transparent?: boolean;
}

const defaultButtonStyles: CSSObject = {
    height: ButtonHeight,
    paddingLeft: SidePadding,
    paddingRight: SidePadding,
};

const textButtonStyles: CSSObject = {
    height: 20,
    paddingLeft: 0,
    paddingRight: 0,
    width: "max-content",
};

export const useButtonStyles = createStyles((theme, { isIconOnly, variant }: Arguments) => ({
    root: {
        borderRadius: 0,
        ...defaultButtonStyles,
        ...(variant === "white" && { ...textButtonStyles }),
        ...getStylesForType(variant, theme),
        ...getStylesForIconOnly(isIconOnly),
    },
    leftIcon: {
        marginRight: isIconOnly ? 0 : IconGap,
    },
    rightIcon: {
        marginLeft: isIconOnly ? 0 : IconGap,
    },
}));
