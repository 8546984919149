import { $FeedbackCreate, FeedbackCreate } from "features/footer/ui/FeedbackModal/api/types";
import { BaseApi } from "shared/api/baseApi";
import { instance } from "src/application/config/axios";

class FeedbackApi extends BaseApi {
    async create(request: FeedbackCreate) {
        return this.instance.post("/feedback", $FeedbackCreate.parse(request));
    }
}
export const feedbackApi = new FeedbackApi(instance);
