import { Box, Stack } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { useRouter } from "next/router";
import { CategoriesData } from "shared/api/categories/types";
import { Color } from "styles/index";
import { prepareCategory } from "../CategorySlide";

interface Props {
    category: CategoriesData;
}

export function CategoryItem({ category }: Readonly<Props>) {
    const { ref, hovered } = useHover();
    const { asPath } = useRouter();

    const isOnCategory = category.slug && asPath.includes(category.slug);
    const withUnderline = isOnCategory || hovered;

    return (
        <Stack ref={ref} mx={1} h={50} px={14}>
            {prepareCategory(category)}
            <Box
                mt={3}
                sx={{
                    borderBottom: `3px solid ${Color.main_Black}`,
                    width: withUnderline ? "100%" : 0,
                    transition: "width .2s linear",
                }}
            />
        </Stack>
    );
}
