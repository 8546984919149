import StyledAccordion from "../../Accordion/StyledAccordion";
import { StyledButton } from "../../Button/StyledButton";
import { StyledCheckbox } from "../../Checkbox/StyledCheckbox";
import { StyledError } from "../../Error/StyledError";
import { StyledInput } from "../../Input/StyledInput";
import { StyledLink } from "../../Link";
import { StyledSelect } from "../../Select/StyledSelect";
import { StyledSwitch } from "../../Switch/StyledSwitch";

export {
    StyledCheckbox as Checkbox,
    StyledError as Error,
    StyledInput as Input,
    StyledSelect as Select,
    StyledSwitch as Switch,
    StyledButton as Button,
    StyledLink as Link,
    StyledAccordion as Accordion,
};
