import { Box, Flex } from "@mantine/core";
import NoSsr from "shared/components/NoSsr";
import MaxWidthLayout from "shared/layouts/MaxWidthLayout";
import { IPageLayout } from "shared/types/navigation";
import { BreadCrumbs } from "shared/ui/BreadCrumbs";
import { ButtonToLegacySite } from "shared/ui/ButtonToLegacySite";
import { Banner } from "widgets/Banner";
import Footer from "widgets/footer/ui/Footer";
import { Header } from "widgets/Header";

export default function MainLayout({ children, crumbs }: Readonly<IPageLayout>) {
    return (
        <Flex direction="column" mih="100vh" w="100%">
            <NoSsr placeholder={<Box h={40} w="100%" bg="main_Black"></Box>}>
                <Banner />
            </NoSsr>
            <MaxWidthLayout
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}>
                <Header />
                <BreadCrumbs list={crumbs} />
                <Box component="main" style={{ flex: 1 }}>
                    {children}
                </Box>
                <Footer />
            </MaxWidthLayout>
            <ButtonToLegacySite />
        </Flex>
    );
}
