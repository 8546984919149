import React, { forwardRef, HTMLInputTypeAttribute, memo, useCallback, useMemo, useState } from "react";
import { IconKey } from "public/icons/IconKey";
import { IconUnvisible } from "public/icons/IconUnvisible";
import { IconVisible } from "public/icons/IconVisible";
import { IconX } from "public/icons/IconX";
import { usePassword } from "shared/hooks/usePassword";
import { ButtonShape, IconButton } from "shared/ui/IconButton";
import { useBottomLineStyles, useDefaultInputStyles, useSearchStyles } from "shared/ui/Input/StyledInput/styles";
import { getInputVariant } from "shared/ui/Input/StyledInput/utils";
import { BaseInput, BaseInputProps } from "../BaseInput";

export interface StyledInputProps extends Omit<BaseInputProps, "value" | "variant"> {
    value?: string | number;
    icon?: JSX.Element;
    rightSection?: JSX.Element;
    type?: HTMLInputTypeAttribute | "mask";
    variant?: BaseInputProps["variant"] | "bottomLine" | "search";
    onClear?: () => void;
}

export const StyledInput = memo(
    forwardRef<HTMLDivElement, StyledInputProps>((props, ref) => {
        const { type, icon: iconProps, rightSection, variant, onClear, ...others } = props;

        const isPasswordField = useMemo(() => type === "password", [type]);
        const { isPasswordVisible, toggleVisibility } = usePassword(isPasswordField);

        const getIcon = useCallback((): StyledInputProps["icon"] => {
            if (isPasswordField) {
                return iconProps ?? <IconKey size={20} />;
            }
            return iconProps;
        }, [isPasswordField, iconProps]);
        const icon = useMemo(() => getIcon(), [isPasswordField, iconProps]);

        const getType = useMemo((): HTMLInputTypeAttribute | undefined => {
            if (isPasswordField && isPasswordVisible) {
                return "password";
            }
            if (isPasswordField && !isPasswordVisible) {
                return "text";
            }
            return props.type;
        }, [isPasswordField, isPasswordVisible, props.type]);

        const getRightSection = useCallback((): JSX.Element | null => {
            if (rightSection) return rightSection;
            if (isPasswordField)
                return (
                    <IconButton
                        icon={isPasswordVisible ? <IconUnvisible /> : <IconVisible />}
                        onClick={toggleVisibility}
                        shape={ButtonShape.noBorder}
                        tabIndex={-1}
                    />
                );
            if (!!onClear && !!props.value)
                return <IconButton icon={<IconX />} shape={ButtonShape.noBorder} onClick={onClear} />;
            return null;
        }, [rightSection, isPasswordField, isPasswordVisible, props.value, onClear]);

        const right = useMemo(() => getRightSection(), [rightSection, isPasswordField, isPasswordVisible]);

        const [focused, setFocused] = useState(false);
        const isFloating = !!String(props.value).trim() || focused;
        const { classes: defaultClasses } = useDefaultInputStyles({ floating: isFloating, icon: !!icon });
        const { classes: buttonLineClasses } = useBottomLineStyles();
        const { classes: searchClasses } = useSearchStyles();

        const getClassNames = useCallback(() => {
            if (variant == "bottomLine") return buttonLineClasses;
            if (variant == "search") return searchClasses;
            return defaultClasses;
        }, [variant, isFloating]);

        const classNames = getClassNames();

        return (
            <BaseInput
                {...others}
                ref={ref}
                variant={getInputVariant(variant)}
                classNames={classNames}
                sx={{
                    input: {
                        "&::-ms-reveal, &::-ms-clear": {
                            display: "none",
                        },
                    },
                }}
                type={getType}
                icon={icon}
                rightSection={right}
                onFocus={(event) => {
                    others.onFocus?.(event);
                    setFocused(true);
                }}
                onBlur={(event) => {
                    others.onBlur?.(event);
                    setFocused(false);
                }}
                onKeyDown={(event) => {
                    props.onKeyDown?.(event);
                    if (props.type === "number") {
                        if (["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(event.code)) {
                            return;
                        }
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }
                }}
            />
        );
    })
);
StyledInput.displayName = "StyledInput";
