import Head from "next/head";
import MainLayout from "shared/layouts/Main/MainLayout";
import { IPage } from "shared/types/navigation";
import { ScrollRestoration } from "./components";

/**
 * Враппер для страниц в /pages. Подставляет SEO тэги и title для страницы, прокидывает инфу по странице в Layout
 */
export function Page({
    title,
    Component,
    Layout = MainLayout,
    crumbs,
    metaTagDescription,
    metaTagKeywords,
    metaTagTitle,
    canonicalHref,
}: IPage) {
    return (
        <>
            <ScrollRestoration />
            <Head>
                {title && <title>{title}</title>}
                {metaTagTitle && <meta name="title" content={metaTagTitle} />}
                {metaTagKeywords && <meta name="keywords" content={metaTagKeywords} />}
                {metaTagDescription && <meta name="description" content={metaTagDescription} />}
                {canonicalHref && <link rel="canonical" href={canonicalHref} />}
            </Head>
            <Layout crumbs={crumbs} title={title}>
                <Component />
            </Layout>
        </>
    );
}
