import { BaseInputProps } from "shared/ui/Input/BaseInput";
import { StyledInputProps } from "shared/ui/Input/StyledInput/index";

export const getInputVariant = (variants: StyledInputProps["variant"]): BaseInputProps["variant"] => {
    if (variants == "bottomLine" || variants == "search") return;
    return variants;
};

export const removeNotNumberSymbols = (str: string): string => {
    return str.replace(/\D+/g, "");
};
