import { ChangeEvent, FocusEvent, memo, useCallback, useMemo } from "react";
import { Stack } from "@mantine/core";
import { useField } from "formik";
import { FError } from "../../Error/FError";
import { StyledInput, StyledInputProps } from "../StyledInput";
import { removeNotNumberSymbols } from "../StyledInput/utils";

export interface FormikInputProps extends Omit<StyledInputProps, "value"> {
    name: string;
    hideErrorText?: boolean;
    hideError?: boolean;
}

export const FInput = memo(function FInput({ hideErrorText, hideError = false, ...props }: FormikInputProps) {
    const { name, readOnly, disabled, onChange, onBlur, type } = props;
    const [field, meta, helpers] = useField(name);

    const isError = useMemo(() => !hideError && !!meta.error && !!meta.touched, [meta.error, meta.touched, hideError]);
    // const isShowTooltip = useMemo(() => props.variant == "bottomLine" && isError, [props.variant, isError]);
    // const { classes: tooltipClasses } = useTooltipStyles();

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            onChange && onChange(e);
            if (type === "mask") {
                helpers.setValue(removeNotNumberSymbols(e.currentTarget.value));
            }
        },
        [onChange, type]
    );

    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement, Element>) => {
            field.onBlur(e);
            onBlur?.(e);
        },
        [onBlur]
    );

    return (
        <Stack>
            {/* TODO: вынести использование Tooltip в отдельный компонент TooltipInput, проверить ререндеры */}
            {/* <Tooltip label={meta.error} disabled={!isShowTooltip} withArrow arrowSize={10} classNames={tooltipClasses}> */}
            <StyledInput
                {...props}
                value={field.value ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
                error={isError}
                disabled={disabled || readOnly}
            />
            {/* </Tooltip> */}
            {!hideErrorText && <FError name={name} />}
        </Stack>
    );
});
