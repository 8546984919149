import { memo, useState } from "react";
import { Center, Flex, HoverCard } from "@mantine/core";
import { LinkProps } from "next/link";
import { IconCircleDown } from "public/icons/IconCircleDown";
import { StyledLink } from "shared/ui/Link";
import Typography, { TypographyProps } from "shared/ui/Typography/Typography";
import { HoverItem } from "./HoverItem";

export interface ILink {
    id: string;
    text: string;
    href: LinkProps["href"];
}

export interface ILinkGroup extends Omit<ILink, "href"> {
    subcategory: ILink[];
}

interface Props {
    label: string;
    tag?: TypographyProps["tag"];
    options?: ILink[];
    withArrow?: boolean;
    withCursorPointer?: boolean;
    targetHref?: string;
}

export const HoverMenu = memo(function HoverMenu({
    label,
    tag = "mSemibold",
    options = [],
    withArrow,
    targetHref,
    withCursorPointer,
}: Props) {
    const [open, setOpen] = useState(false);
    return (
        <Center>
            <HoverCard
                shadow="md"
                position="bottom-start"
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                withinPortal>
                <HoverCard.Target>
                    <Flex align="center" sx={{ cursor: withCursorPointer ? "pointer" : "auto" }}>
                        {targetHref ? (
                            <StyledLink href={targetHref}>
                                <Typography tag={tag}>{label}</Typography>
                            </StyledLink>
                        ) : (
                            <Typography tag={tag}>{label}</Typography>
                        )}
                        {withArrow && (
                            <IconCircleDown
                                size={20}
                                style={{
                                    transform: open ? "rotate(180deg)" : "rotate(0)",
                                    transition: "all 0.1s linear",
                                }}
                            />
                        )}
                    </Flex>
                </HoverCard.Target>
                <HoverCard.Dropdown p={0}>
                    {options.map((item) => (
                        <HoverItem item={item} key={item.id} />
                    ))}
                </HoverCard.Dropdown>
            </HoverCard>
        </Center>
    );
});
