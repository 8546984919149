import { Box } from "@mantine/core";
import classNames from "classnames";
import parse from "html-react-parser";
import { montserrat, openSans } from "providers/Theme/fonts";
import { useOptions } from "./utils";
import styles from "./styles.module.scss";

interface Props {
    htmlString: string;
}

function HTMLParser({ htmlString }: Readonly<Props>): JSX.Element {
    const options = useOptions();

    return (
        <Box className={classNames(styles.parser, montserrat.variable, openSans.variable)}>
            {parse(htmlString, options)}
        </Box>
    );
}

export default HTMLParser;
