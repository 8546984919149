import { createStyles } from "@mantine/core";

export const useBasketHoverCardStyles = createStyles((theme) => ({
    dropdown: {
        padding: 10,
        paddingRight: 0,
        boxShadow: `0px 4px 24px ${theme.fn.rgba(theme.colors.second_white[0], 0.1)}`,
    },
    scrollAreaRoot: {
        " .mantine-ScrollArea-thumb": {
            backgroundColor: theme.colors.main_Black[0],
        },
    },
    actions: {
        padding: 20,
        paddingTop: 30,
    },
}));
