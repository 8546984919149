import { BaseApi } from "shared/api/baseApi";
import { instance } from "src/application/config/axios";
import { $BannerResponse, BannerResponse } from "./types";

class BannerApi extends BaseApi {
    async indexBanner(): Promise<BannerResponse> {
        return $BannerResponse.parse(await this.instance.get("scrolling-rows"));
    }
}

export const bannerApi = new BannerApi(instance);
