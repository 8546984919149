import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationKeys, QueryKeys } from "shared/api/keys";
import { usersApi } from "../../requests";
import { CreateContractorRequest } from "../../types";

const useUserCreateContactor = () => {
    const queryClient = useQueryClient();
    return useMutation(
        [MutationKeys.USER_CONTRACTOR_CREATE],
        (request: CreateContractorRequest) => usersApi.createUserContractor(request),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.USER_CONTRACTORS_INDEX]);
            },
        }
    );
};

export default useUserCreateContactor;
