import { useMemo } from "react";
import { Box } from "@mantine/core";
import { CartIndex } from "shared/api/cart/types";
import BasketCartItem from "../BasketCartItem";
import { useBasketCartListStyles } from "./styles";

interface BasketCartListProps {
    cart?: CartIndex;
}

function BasketCartList({ cart }: BasketCartListProps): JSX.Element {
    const { classes } = useBasketCartListStyles();

    const rows = useMemo(
        () => cart?.items.map((value) => <BasketCartItem key={value.id} cartItem={value} />),
        [cart?.items]
    );

    return <Box className={classes.root}>{rows}</Box>;
}

export default BasketCartList;
