import { memo } from "react";
import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { IProfile } from "shared/api/user/types";
import { isErrorWithFields } from "./guards";

export * from "./getPluralString";
export * from "./getFileSize";

export const getFio = (profile?: IProfile) => {
    if (!profile) return "";
    return profile.fullName;
    // return `${profile.firstName ? profile.firstName + " " : ""}${profile.middleName ? profile.middleName + " " : ""}${
    //     profile.lastName
    // }`;
};

export const upFirstCharInString = (str: string): string => {
    return str[0].toUpperCase() + str.slice(1);
};

export type TError = AxiosError<{ status: number; success: boolean; errors: Record<string, string[]> }>;

// По факту сюда может придти что угодно
// Но если в этом чем угодно есть response.data.errors.validation то функция обработает эти значения и установит соответствующие ошибки для формы
export const syncErrors = <T>(error: TError | unknown, helpers: FormikHelpers<T>) => {
    if (!isErrorWithFields(error)) return;
    const validationErrors = error.response?.data?.errors;
    if (validationErrors) {
        Object.entries(validationErrors).forEach(([key, value]) => helpers.setFieldError(key, value[0]));
    }
};

export const getUniqueItems = <T>(arr: T[]): T[] => {
    const set = new Set(arr);
    return Array.from(set);
};

export const genericMemo: <T>(component: T) => T = memo;

/**
 * Фильтрует нулевые и неопределенные значения из массива чисел.
 *
 * @param {Array<number | null | undefined>} values - Массив чисел, который может содержать нулевые или неопределенные значения.
 * @returns {number[]} - Массив только чисел с отфильтрованными нулевыми и неопределенными значениями.
 */
export const filterNumber = (value: number | null | undefined): value is number => typeof value === "number";

/**
 * Находит медиану массива чисел.
 * @param {number[]} numbers - Массив чисел.
 * @returns {number} - Медиана массива.
 */
export const findMedian = (numbers: number[]): number => {
    const sortedNumbers = numbers.slice().sort((a, b) => a - b);
    const length = sortedNumbers.length;

    if (length % 2 === 0) {
        const midIndex = length / 2;
        return (sortedNumbers[midIndex - 1] + sortedNumbers[midIndex]) / 2;
    }

    const midIndex = Math.floor(length / 2);
    return sortedNumbers[midIndex];
};
