import { $PaginationFilter, $QueryFilter } from "shared/api/types";
import { z } from "zod";

// https://github.com/colinhacks/zod/issues/1333
export const $ProductsIndexFilters = z.object({
    filtering: z.record(z.string().array().or(z.boolean().or(z.string()))).nullish(),
});
export type ProductsIndexFilters = z.infer<typeof $ProductsIndexFilters>;
export const $ProductsIndexRequest = $PaginationFilter.merge($QueryFilter).merge($ProductsIndexFilters);

export type ProductsIndexRequest = z.infer<typeof $ProductsIndexRequest>;

// ========================================   INDEX  =========================================

export type Mark = z.infer<typeof $Mark>;
const $Mark = z.object({ id: z.number().optional(), color: z.string(), content: z.string() });

export type StockWarehouse = z.infer<typeof $StockWarehouse>;
const $StockWarehouse = z.object({ title: z.string(), isActive: z.boolean() });

export type StockPending = z.infer<typeof $StockPending>;
const $StockPending = z.object({
    amount: z.number(),
    available: z.number(),
    expectedAt: z.string(),
});

export type Stock = z.infer<typeof $Stock>;
const $Stock = z.object({
    available: z.number(),
    reserved: z.number(),
    main: z.boolean(),
    warehouse: $StockWarehouse,
    pendings: z.array($StockPending),
});

export type SkuAttributeColor = z.infer<typeof $SkuAttributeColor>;
const $SkuAttributeColor = z.object({
    id: z.string(),
    cover: z.string().nullish(),
    alt: z.string().optional(),
    selected: z.boolean().optional(),
});

export type SkuAttributeVariation = z.infer<typeof $SkuAttributeVariation>;
const $SkuAttributeVariation = z.object({
    id: z.string(),
    value: z.string(),
    type: z.string(),
    selected: z.boolean().optional(),
});

export type SkuAttributes = z.infer<typeof $SkuAttributes>;
const $SkuAttributes = z.object({
    color: z.array($SkuAttributeColor),
    variation: z.array($SkuAttributeVariation).optional(),
});

export type Offer = z.infer<typeof $Offer>;
export const $Offer = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    model: z.string(),
    price: z.number(),
    basePrice: z.number().optional(),
    count: z.number(),
    hasOwnDiscount: z.boolean().nullish(),
    cover: z.string(),
    stock: z.array($Stock).nullish(),
    images: z.array(z.string()),
    skuAttributes: $SkuAttributes,
    onOrder: z.boolean().nullish(),
    onOrderLabel: z.string().nullish(),
    onOrderText: z.string().nullish(),
    showOfferForSeo: z.boolean().nullish(),
});

export type OfferWithoutAttribute = z.infer<typeof $OfferWithoutAttribute>;
export const $OfferWithoutAttribute = $Offer.omit({ skuAttributes: true });

export type Product = z.infer<typeof $Product>;
export const $Product = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    slugCategory: z.string(),
    material: z.string(),
    marks: z.array($Mark),
    // offer приходит для отображения карточками(флешки и тп)
    // остальные варианты товара(минимум информации) приходят в skuAttributes
    // для детальной информации по предложению из skuAttributes нужно запрашивать информацию через POST /api/v2/products/{product}/offer-sku-attrs
    // {product} - это id или slug товара
    offer: $Offer.nullish(),
    // offers приходит для отображения в линию(ручки и тп)
    // в этом случае offer и sku_attributes не приходят
    // все предложения по товару приходят разом
    offers: z.array($OfferWithoutAttribute),
    isRotate: z.boolean().nullish(),
    onOrder: z.boolean().nullish(),
    onOrderLabel: z.string().nullish(),
    onOrderText: z.string().nullish(),
    promotion: z.string().nullish(),
    promotionLink: z.string().nullish(),
    printingMethodsTooltip: z.array(z.string()).nullish(),
});

// ================================  Детальная страница офера    ====================================

export type DrawingType = z.infer<typeof $DrawingType>;
export const $DrawingType = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    tooltipText: z.string().optional(),
});

export type Category = z.infer<typeof $Category>;
export const $Category = z.object({
    id: z.number(),
    slug: z.string(),
    name: z.string(),
    description: z.string(),
    isActive: z.boolean(),
    variantView: z.string(),
    tagTitle: z.string(),
    tagMetaTitle: z.string(),
    tagMetaKeywords: z.string(),
    tagMetaDescription: z.string(),
    subcategory: z.array(z.string()).optional(),
    parent: z
        .object({
            id: z.number(),
            slug: z.string(),
            name: z.string(),
            description: z.string(),
            isActive: z.boolean(),
            variantView: z.string(),
            tagTitle: z.string(),
            tagMetaTitle: z.string(),
            tagMetaKeywords: z.string(),
            tagMetaDescription: z.string(),
            parent: z.null(),
        })
        .nullable(),
});

export type Attribute = z.infer<typeof $Attribute>;
export const $Attribute = z.object({
    id: z.number(),
    key: z.string(),
    name: z.string(),
    value: z.string(),
    valueUnit: z.string(),
});

export type DetailedProduct = z.infer<typeof $DetailedProduct>;
export const $DetailedProduct = z.object({
    id: z.number(),
    material: z.string(),
    name: z.string(),
    description: z.string(),
    brandLogo: z.string().nullish(),
    slug: z.string(),
    promotion: z.string().nullable(),
    promotionLink: z.string().nullable(),
    unitWeight: z.string(),
    unitVolume: z.string(),
    marks: z.array($Mark),
    drawingTypes: z.array($DrawingType),
    layoutsDefault: z.array(z.string()),
    categories: z.array($Category),
    attributes: z.array($Attribute),
    isRotate: z.boolean(),
    onOrder: z.boolean(),
    onOrderLabel: z.string(),
    onOrderText: z.string(),
    tagTitle: z.string(),
    tagMetaTitle: z.string(),
    tagMetaKeywords: z.string(),
    tagMetaDescription: z.string(),
    categoryCanonical: z.string().nullish(),
    offerCanonical: z.string().nullish(),
});

export const $Recommendation = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    slugCategory: z.string(),
    material: z.string(),
    marks: z.array($Mark),
    offer: $Offer,
    offers: z.array($Offer),
    isRotate: z.boolean(),
    onOrder: z.boolean(),
    onOrderLabel: z.string(),
    onOrderText: z.string(),
});

export type Colors = z.infer<typeof $Colors>;
export const $Colors = z.object({
    id: z.number(),
    code: z.string(),
    value: z.string(),
    isException: z.boolean().nullish(),
});

export const $AdditionalOption = z.object({
    id: z.number(),
    title: z.string(),
    amount: z.number(),
    unitType: z.string(),
    group: z.string(),
    selected: z.boolean(),
    isSend: z.boolean(),
    disable: z.boolean(),
});

export const $ConsolidatedAdditionalOption = z.object({
    id: z.number(),
    title: z.string(),
    subTitle: z.string(),
    amount: z.number(),
    unitType: z.string(),
    onlySubTitle: z.boolean(),
    selected: z.boolean(),
    isSend: z.boolean(),
    disable: z.boolean(),
});

export type PrintingMethod = z.infer<typeof $PrintingMethod>;
export const $PrintingMethod = z.object({
    id: z.number(),
    title: z.string(),
    isColor: z.boolean(),
    maxCountColors: z.number(),
    isCentimeter: z.boolean(),
    tooltip: z.string(),
    slug: z.string(),
    colors: z.array($Colors),
    isOfferWithSubstrate: z.boolean(),
    notifications: z.array(z.string()),
    additionalOptions: z.array($AdditionalOption),
    consolidatedAdditionalOptions: z.array($ConsolidatedAdditionalOption),
    relevanceDate: z.string().nullish(),
    seasonalDate: z.string().nullish(),
});

export type Coordinates = z.infer<typeof $Coordinates>;
export const $Coordinates = z.object({ height: z.number(), left: z.number(), top: z.number(), width: z.number() });

export type RectPrintingArea = z.infer<typeof $RectPrintingArea>;
export const $RectPrintingArea = z.object({
    id: z.number(),
    title: z.string(),
    type: z.literal("rect"),
    coordinates: z.object({
        height: z.number(),
        width: z.number(),
        left: z.number(),
        top: z.number(),
    }),
    printingMethods: z.array($PrintingMethod),
});

export type CirclePrintingArea = z.infer<typeof $CirclePrintingArea>;
export const $CirclePrintingArea = z.object({
    id: z.number(),
    title: z.string(),
    type: z.literal("circle"),
    coordinates: z.object({
        cx: z.number(),
        cy: z.number(),
        r: z.number(),
    }),
    printingMethods: z.array($PrintingMethod),
});

export type PrintingArea = z.infer<typeof $PrintingArea>;
export const $PrintingArea = $RectPrintingArea.or($CirclePrintingArea);

export type ViewBox = z.infer<typeof $ViewBox>;
export const $ViewBox = z.object({
    top: z.string(),
    left: z.string(),
    with: z.string().optional(),
    height: z.string(),
});

export type PrintingAreaImage = z.infer<typeof $PrintingAreaImage>;
export const $PrintingAreaImage = z.object({
    id: z.number(),
    name: z.string(),
    path: z.string(),
    type: z.string(),
    size: z.number(),
    viewBox: $ViewBox,
});

export type DetailedOffer = z.infer<typeof $DetailedOffer>;
export const $DetailedOffer = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    model: z.string(),
    price: z.number(),
    basePrice: z.number(),
    count: z.number(),
    stock: z.array($Stock),
    images: z.array(z.string()),
    cover: z.string(),
    product: $DetailedProduct,
    recommendations: z.array($Recommendation),
    printingAreas: z.array($PrintingArea),
    printingAreaImage: $PrintingAreaImage.nullable(),
    onOrder: z.boolean(),
    onOrderLabel: z.string(),
    onOrderText: z.string(),
    skuAttributes: $SkuAttributes,
    showOfferForSeo: z.boolean().nullish(),
    isSignal: z.boolean().nullish(),
});

export type AttributeParams = { colorId?: string; variationId?: string };
