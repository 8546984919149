/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, HoverCard, HoverCardProps, ScrollArea } from "@mantine/core";
import { useIndexCart } from "shared/api/cart/hooks/useIndexCart";
import { Button } from "shared/ui/Forms/Styled";
import { BasketCartList } from "./components";
import { useBasketHoverCardStyles } from "./styles";

export type BasketHoverCardProps = HoverCardProps;

export function BasketHoverCard({ children, ...props }: BasketHoverCardProps) {
    const { classes } = useBasketHoverCardStyles();
    const { data, isLoading } = useIndexCart();

    const isDisabledDropdown = isLoading || !data?.items.length;

    return (
        <HoverCard {...props} width={350} closeDelay={500} disabled={isDisabledDropdown}>
            <HoverCard.Target>
                <Box>{children}</Box>
            </HoverCard.Target>
            <HoverCard.Dropdown className={classes.dropdown}>
                {/*
                    // @ts-ignore */}
                <ScrollArea.Autosize className={classes.scrollAreaRoot} maxHeight={550} scrollbarSize={3}>
                    <BasketCartList cart={data} />
                </ScrollArea.Autosize>
                <Box className={classes.actions}>
                    <Button label="Перейти в корзину" href="/cart" fullWidth />
                </Box>
            </HoverCard.Dropdown>
        </HoverCard>
    );
}
