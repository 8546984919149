import { memo } from "react";
import classNames from "classnames";
import { BaseLink } from "shared/ui/Link";
import { BaseLinkProps } from "shared/ui/Link/Base";

type Props<T extends boolean> = BaseLinkProps<T>;

function StyledLink<T extends boolean>(props: Props<T>): JSX.Element {
    const { className, style, ...restProps } = props;
    return (
        <BaseLink
            style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...style,
            }}
            className={classNames(className)}
            {...restProps}
        />
    );
}

export default memo(StyledLink);
