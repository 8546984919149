import NextLink, { LinkProps } from "next/link";
import { getHref } from "shared/ui/Link/Base/utils";

export interface BaseLinkProps<IsExternalLink extends boolean | undefined = false>
    extends Omit<LinkProps, "href" | "title"> {
    className?: string;
    /**
     * Внешняя ссылка должна быть string, начинающейся с протокола (например https://), или URL объектом
     */
    href: IsExternalLink extends true ? string | URL : LinkProps["href"];
    isExternalLink?: IsExternalLink;
}

function BaseLink<IsExternalLink extends boolean | undefined = false>(
    props: BaseLinkProps<IsExternalLink>
): JSX.Element {
    const { href: hrefProps, isExternalLink, ...restPros } = props;
    const href = getHref(hrefProps);

    return <NextLink href={href} target={isExternalLink ? "_blank" : undefined} {...restPros} />;
}

export default BaseLink;
