import { memo } from "react";
import { Center, Menu, MenuProps } from "@mantine/core";
import { IconMagnifyingGlass } from "public/icons/IconMagnifyingGlass";
import { contentMaxWidth } from "styles/index";

export const SearchMenu = memo(function SearchMenu({ children, ...props }: MenuProps) {
    return (
        <Menu {...props} closeOnClickOutside shadow="md" width={contentMaxWidth}>
            <Menu.Target>
                <Center style={{ cursor: "pointer" }}>
                    <IconMagnifyingGlass width={20} />
                </Center>
            </Menu.Target>
            <Menu.Dropdown
                p={30}
                sx={{
                    //похоже это единственный способ сместить дропдаун относительно таргета сразу по ДВУМ осям
                    top: "86px !important",
                    left: "20px !important",
                }}>
                {children}
            </Menu.Dropdown>
        </Menu>
    );
});
