import { Indicator, Loader } from "@mantine/core";
import { IconShoppingCart } from "public/icons/IconShoppingCart";
import { useShowCartCount } from "shared/api/cart/hooks/useShowCartCount";
import { IconButton } from "shared/ui/IconButton";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";

export function BasketButton() {
    const { data = { count: 0 }, isLoading, isFetching } = useShowCartCount();
    return (
        <StyledLink href="/cart" isExternalLink={false}>
            <IconButton
                size={20}
                icon={
                    <Indicator
                        inline
                        size={20}
                        offset={0}
                        position="top-end"
                        color="main_Black"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        label={
                            isLoading || isFetching ? (
                                <Loader size={13} color="second_white" />
                            ) : (
                                <Typography color="white" tag="mSemibold">
                                    {data.count}
                                </Typography>
                            )
                        }>
                        <IconShoppingCart width={20} />
                    </Indicator>
                }
            />
        </StyledLink>
    );
}
