import { IconComponent } from "public/icons/types";

export const IconMessage: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        color={color}
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path d="M20.3398 9.31988L6.33976 2.31988C5.78725 2.04489 5.16338 1.94699 4.55319 2.03954C3.94301 2.13208 3.37622 2.41055 2.93009 2.837C2.48396 3.26345 2.18021 3.81711 2.06025 4.42249C1.94029 5.02788 2.00996 5.65554 2.25976 6.21988L4.65976 11.5899C4.71422 11.7197 4.74226 11.8591 4.74226 11.9999C4.74226 12.1407 4.71422 12.2801 4.65976 12.4099L2.25976 17.7799C2.05646 18.2366 1.97051 18.7369 2.00974 19.2353C2.04896 19.7336 2.2121 20.2143 2.48434 20.6336C2.75658 21.0529 3.12928 21.3975 3.56858 21.6361C4.00788 21.8747 4.49984 21.9998 4.99976 21.9999C5.46799 21.9952 5.92925 21.8859 6.34976 21.6799L20.3498 14.6799C20.8464 14.4301 21.2638 14.0472 21.5555 13.574C21.8471 13.1007 22.0016 12.5558 22.0016 11.9999C22.0016 11.444 21.8471 10.899 21.5555 10.4258C21.2638 9.95258 20.8464 9.5697 20.3498 9.31988H20.3398ZM19.4498 12.8899L5.44976 19.8899C5.26592 19.9782 5.05949 20.0081 4.85815 19.9757C4.6568 19.9434 4.47017 19.8502 4.32327 19.7087C4.17638 19.5673 4.07624 19.3843 4.03629 19.1843C3.99634 18.9843 4.01849 18.7769 4.09976 18.5899L6.48976 13.2199C6.5207 13.1482 6.54741 13.0747 6.56976 12.9999H13.4598C13.725 12.9999 13.9793 12.8945 14.1669 12.707C14.3544 12.5195 14.4598 12.2651 14.4598 11.9999C14.4598 11.7347 14.3544 11.4803 14.1669 11.2928C13.9793 11.1052 13.725 10.9999 13.4598 10.9999H6.56976C6.54741 10.9251 6.5207 10.8516 6.48976 10.7799L4.09976 5.40988C4.01849 5.22285 3.99634 5.01543 4.03629 4.81545C4.07624 4.61547 4.17638 4.43249 4.32327 4.29103C4.47017 4.14958 4.6568 4.05641 4.85815 4.02404C5.05949 3.99166 5.26592 4.02161 5.44976 4.10988L19.4498 11.1099C19.6136 11.1938 19.751 11.3213 19.847 11.4783C19.943 11.6354 19.9938 11.8158 19.9938 11.9999C19.9938 12.1839 19.943 12.3644 19.847 12.5214C19.751 12.6785 19.6136 12.806 19.4498 12.8899Z" />
    </svg>
);
