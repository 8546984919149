import { Stack } from "@mantine/core";
import { useField } from "formik";
import { FError } from "shared/ui/Error/FError";
import StyledTextarea, { StyledTextareaProps } from "shared/ui/Textarea/StyledTextarea";

export interface FTextareaProps extends StyledTextareaProps {
    name: string;
}
function FTextarea({ onChange, onBlur, onFocus, ...props }: FTextareaProps): JSX.Element {
    const name = props.name;
    const [field, meta] = useField<string>(name);
    const isError = !!meta.error && !!meta.touched;
    return (
        <Stack h="100%">
            <StyledTextarea
                value={field.value ?? ""}
                onChange={(e) => {
                    field.onChange(e);
                    onChange && onChange(e);
                }}
                onBlur={(e) => {
                    field.onBlur(e);
                    onBlur?.(e);
                }}
                error={isError}
                {...props}
            />
            <FError name={name} />
        </Stack>
    );
}
export default FTextarea;
