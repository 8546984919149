import { MantineNumberSize, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

/**
 * @deprecated "use client" only
 */
export const useSmallerThanMedia = (size: MantineNumberSize, initialValue?: boolean) => {
    const { breakpoints } = useMantineTheme();
    const breakpoint = typeof size === "number" ? size : breakpoints[size];

    return useMediaQuery(`(max-width: ${breakpoint}px)`, initialValue);
};

/**
 * @deprecated "use client" only
 */
export const useLargerThanMedia = (size: MantineNumberSize, initialValue?: boolean) => {
    const { breakpoints } = useMantineTheme();
    const breakpoint = typeof size === "number" ? size : breakpoints[size];

    return useMediaQuery(`(min-width: ${breakpoint + 1}px)`, initialValue);
};
