import { BaseApi } from "shared/api/baseApi";
import { instance } from "src/application/config/axios";
import { SubscribeRequest } from "./types";

class SubscribeApi extends BaseApi {
    async subscribe(payload: SubscribeRequest) {
        return this.instance.post("mailing-subscriptions", payload);
    }
}

export const subscribeApi = new SubscribeApi(instance);
