import { memo, useEffect, useState } from "react";
import { Center, Flex } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { getCookie, setCookie } from "cookies-next";
import { openLoginModal } from "features/header/ui/LoginModal";
import { useRouter } from "next/router";
import { IconUserCircle } from "public/icons/IconUserCircle";
import { QueryKeys } from "shared/api/keys";
import { useUserData } from "shared/api/user/hooks/";
import { Button, Link } from "shared/ui/Forms/Styled";
import { Spinner } from "shared/ui/Spinner";
import Typography from "shared/ui/Typography/Typography";
import { ECookies } from "src/application/config/axios/interceptors/request";
import { openUpdatePasswordModal } from "../UpdatePasswordModal";

export const AuthSection = memo(function AuthSection() {
    const { query } = useRouter();
    const client = useQueryClient();
    const { data, isLoading, isFetching } = useUserData();

    //открываем модалку если есть соответствующие query params
    useEffect(() => {
        if (!!query.email && !!query.token) {
            openUpdatePasswordModal();
        }
    }, [query]);

    //это сделано для избежания ошибки гидрации, при которой
    //на сервере где нет токена не рендерится лоадер,
    //а на клиенте, где токен есть лоадер рендерится
    //что приводит к несовпадению клиента и сервера и пересчёту всей страницы
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setMounted(true);
        }
    }, []);

    //этот эффект отвечает за восстановление сессии, при включенном флаге rememberMe
    //он существует т.к. мы не можем написать в useUserData enbaled: !!localStorage('accessToken'),
    useEffect(() => {
        const rememberMe = localStorage.getItem("rememberMe");
        if (rememberMe !== "true") return;

        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");

        if (!!accessToken && !!refreshToken && !getCookie(ECookies.accessToken) && !getCookie(ECookies.refreshToken)) {
            setCookie(ECookies.accessToken, accessToken);
            setCookie(ECookies.refreshToken, refreshToken);
            client.fetchQuery([QueryKeys.USER_DATA]);
        }
    }, []);

    if ((isLoading || isFetching) && mounted)
        return (
            <Center miw={75}>
                <Spinner size={20} />
            </Center>
        );

    if (!!data)
        return (
            <Flex align="center">
                <Link href="/profile/main">
                    <Typography tag="mSemibold">{data?.customerProfile.fullName ?? "Аноним"}</Typography>
                </Link>
            </Flex>
        );

    return (
        <Button
            label="Войти"
            leftIcon={<IconUserCircle width={20} />}
            onClick={openLoginModal}
            variant="white"
            tag="mSemibold"
        />
    );
});
