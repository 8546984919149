import React, { memo } from "react";
import Typography from "shared/ui/Typography/Typography";

export interface StyledErrorProps {
    text?: string;
}

export const StyledError: React.FC<StyledErrorProps> = memo(({ text }) => {
    return (
        <Typography color="validation_red" size={12}>
            {text}
        </Typography>
    );
});

StyledError.displayName = "StyledError";
