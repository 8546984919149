import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "shared/api/keys";
import { categoriesApi } from "../..";

const useCategories = () => {
    // FIXME: Сделать нормальную подгрузку категорий.
    //  Сейчас получаем 50 т.к. было сказано что больше 10 категорий не будет приходить.
    //  Хоть такая теоретическая возможность и есть.
    return useQuery([QueryKeys.CATEGORIES_INDEX], () => categoriesApi.indexCategories({ perPage: 50 }), {
        cacheTime: 360000,
        staleTime: 60000,
    });
};

export default useCategories;
