import { FC, memo } from "react";
import { Flex, Skeleton } from "@mantine/core";

const CategoriesSectionSkeleton: FC = () => {
    return (
        <Flex gap={30} pl={70}>
            <Skeleton w={100} h={20} />
            <Skeleton w={80} h={20} />
            <Skeleton w={120} h={20} />
            <Skeleton w={160} h={20} />
            <Skeleton w={200} h={20} />
            <Skeleton w={100} h={20} />
            <Skeleton w={80} h={20} />
            <Skeleton w={120} h={20} />
            <Skeleton w={180} h={20} />
        </Flex>
    );
};
export default memo(CategoriesSectionSkeleton);
