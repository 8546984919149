import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationKeys, QueryKeys } from "shared/api/keys";
import { usersApi } from "../../requests";
import { UpdateUserDataRequest } from "../../types";

const useUserUpdateData = () => {
    const queryClient = useQueryClient();
    return useMutation(
        [MutationKeys.USER_DATA_UPDATE],
        (request: UpdateUserDataRequest) => usersApi.updateUserData(request),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.USER_DATA]);
            },
        }
    );
};

export default useUserUpdateData;
