import { IconComponent } from "public/icons/types";

export const IconEnvelopeSimple: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        color={color}
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M3 7V18.75H21V7M3 7V5H21V7M3 7L12 13L21 7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
