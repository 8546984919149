import { useQuery } from "@tanstack/react-query";
import { cartApi } from "shared/api/cart";
import { QueryKeys } from "shared/api/keys";

export const useShowCartCount = () => {
    return useQuery([QueryKeys.CART_COUNT_SHOW], () => cartApi.showCartCount(), {
        staleTime: 100000,
        cacheTime: 100000,
    });
};
