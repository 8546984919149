import { IconComponent } from "public/icons/types";

export const IconUserCircle: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg
        width={size}
        height={size}
        color={color}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.65381 20.5556C6.22742 19.4523 7.10191 18.5257 8.18049 17.8786C9.25906 17.2315 10.4996 16.8889 11.7649 16.8889C13.0302 16.8889 14.2708 17.2314 15.3493 17.8786C16.4279 18.5257 17.3024 19.4522 17.876 20.5556"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle
            cx="12.0002"
            cy="12"
            r="4.88889"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
