import { ReactNode, useState } from "react";
import { Accordion, AccordionProps } from "@mantine/core";
import HTMLParser from "shared/components/HTMLParser";
import { generateValueForAccordion } from "shared/ui/Accordion/BaseAccordion/utils";

export interface AccordionItem {
    name: string;
    description: string;
}
export interface BaseAccordionProps
    extends Omit<AccordionProps<true>, "value" | "defaultValue" | "onChange" | "multiple" | "children" | "chevron"> {
    data: AccordionItem[];
    chevron?: (isOpen: boolean) => ReactNode;
}
function BaseAccordion({ data, chevron, ...props }: Readonly<BaseAccordionProps>): JSX.Element {
    const [selected, setSelected] = useState<string[]>([]);
    return (
        <Accordion multiple={true} value={selected} onChange={setSelected} {...props}>
            {data.map((value, index) => (
                <Accordion.Item key={`${value.name}_${index}`} value={generateValueForAccordion(value.name, index)}>
                    <Accordion.Control
                        chevron={chevron?.(selected.includes(generateValueForAccordion(value.name, index)))}>
                        {value.name}
                    </Accordion.Control>
                    <Accordion.Panel>
                        <HTMLParser htmlString={value.description} />
                    </Accordion.Panel>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}
export default BaseAccordion;
