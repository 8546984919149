import React, { ReactNode, useEffect, useState } from "react";

interface NoSsrProps {
    placeholder?: ReactNode;
}

const NoSsr = ({ children, placeholder = null }: React.PropsWithChildren<NoSsrProps>) => {
    const [Component, setComponent] = useState<ReactNode>(placeholder);

    useEffect(() => {
        if (!children) {
            setComponent(null);
        }
        setComponent(children);
    }, [children]);

    return <React.Fragment>{Component}</React.Fragment>;
};

export default NoSsr;
