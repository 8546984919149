import currency from "currency.js";
import { z } from "zod";

export const pennyToMoney = (penny: number): number => {
    return currency(penny).divide(100).value;
};
export const moneyToPenny = (money: number): number => {
    return currency(money).multiply(100).value;
};
export interface TransformedMoney {
    integerPart: number;
    decimalPart: number;
}
export const transformMoney = (money: number): TransformedMoney => {
    const penny = currency(money).multiply(100).value;
    const integerPart = Math.trunc(money);
    const integerPartToPenny = currency(integerPart).multiply(100).value;
    const decimalPart = currency(penny).subtract(integerPartToPenny).value;
    return {
        integerPart: integerPart,
        decimalPart: decimalPart,
    };
};

export const getFormattedMoney = (value: string | number) => {
    if (!z.number().safeParse(Number(value)).success) {
        return value;
    }

    const valueWithPenny =
        typeof value === "string" ? transformMoney(Number(value)) : transformMoney(pennyToMoney(value));

    if (!valueWithPenny.decimalPart) {
        return valueWithPenny.integerPart.toLocaleString("ru");
    }

    return [valueWithPenny.integerPart.toLocaleString("ru"), valueWithPenny.decimalPart].join(".");
};
