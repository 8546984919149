import { Flex, Loader, UnstyledButton } from "@mantine/core";
import { cartTableSizes } from "entities/cart/cartTableSizes";
import { IconX } from "public/icons/IconX";
import { useRemoveCartItem } from "shared/api/cart/hooks/useRemoveCartItem";
import { CartItem } from "shared/api/cart/types";
import { Link } from "shared/ui/Forms/Styled";
import { formatThousandNumberWithSpaces } from "shared/ui/Input/NumberInput/utils";
import { Picture } from "shared/ui/Picture";
import Typography from "shared/ui/Typography/Typography";
import { pennyToMoney, transformMoney } from "shared/utils/moneyUtils/moneyUtils";
import { useBasketCartItemStyles } from "./styles";

interface BasketCartItemProps {
    cartItem: CartItem;
}

const BasketCartItem = ({ cartItem }: BasketCartItemProps) => {
    const { classes } = useBasketCartItemStyles();
    const { mutate: removeCartItem, isLoading: isLoadingRemove } = useRemoveCartItem();
    const { columnsWidth } = cartTableSizes;
    const { productOffer } = cartItem;

    const linkProductOffer = `/catalog/${
        productOffer.product.categories[0].slug || productOffer.product.categories[0].id
    }/${productOffer.product.slug || productOffer.product.id}/${productOffer.slug}?cartItemId=${cartItem.id}`;

    const name = [productOffer.product.name, productOffer.name].join(", ");

    const totalCost = transformMoney(pennyToMoney(cartItem.totalCostAfterDiscount));

    const handleRemoveItem = () => removeCartItem(cartItem.id);

    return (
        <>
            <Link href={linkProductOffer}>
                <Picture
                    src={productOffer.cover.path}
                    alt={productOffer.cover.name}
                    width={50}
                    height={50}
                    style={{
                        ...(productOffer.product.is_rotate && {
                            transform: "rotate(45deg)",
                        }),
                    }}
                />
            </Link>
            <Flex align="flex-start" direction="column">
                <Link href={linkProductOffer}>
                    <Typography
                        tag="mRegular"
                        fontSize={13}
                        lh={16}
                        pb={5}
                        sx={{
                            ":hover": {
                                textDecoration: "underline",
                            },
                        }}>
                        {name}
                    </Typography>
                </Link>
                <Flex direction="column" pb={5}>
                    <Typography tag="P" className={classes.price}>
                        {`${totalCost.integerPart.toLocaleString("ru")}.${totalCost.decimalPart} руб`}
                    </Typography>
                    <Typography tag="P" className={classes.quantity}>
                        {formatThousandNumberWithSpaces(cartItem.quantity)} шт
                    </Typography>
                </Flex>
            </Flex>

            <UnstyledButton onClick={handleRemoveItem} disabled={isLoadingRemove} h="fit-content" pr={10}>
                {isLoadingRemove ? <Loader size={columnsWidth.remove} /> : <IconX size={columnsWidth.remove} />}
            </UnstyledButton>
        </>
    );
};

export default BasketCartItem;
