import { useMemo, useState } from "react";
import { Box, Collapse, Menu, Stack } from "@mantine/core";
import { QuickSearchResults } from "entities/header/search/QuickSearchResults";
import { ResultsLink } from "entities/header/search/ResultsLink";
import { SearchBar } from "entities/header/search/SearchBar";
import { SearchMenu } from "entities/header/search/SearchMenu";
import { useProductSearch } from "shared/api/products/hooks";
import useDebounce from "shared/hooks/useDebounce";
import { SearchInfo } from "./components";
import { MINIMUM_LENGTH_TO_SEARCH } from "./constants";

export function SearchButton() {
    const [openedSearchMenu, setOpenedSearchMenu] = useState(false);
    const [search, setSearch] = useState("");
    const { value: debouncedSearch, isDebouncing } = useDebounce(search, 300);

    const trimmedValue = debouncedSearch?.trim();

    const {
        data = { data: [], pagination: { total: 0 } },
        isLoading,
        isFetching,
    } = useProductSearch(
        { query: debouncedSearch },
        !!trimmedValue?.trim() && trimmedValue.length > MINIMUM_LENGTH_TO_SEARCH
    );

    const isSearching = useMemo(
        () => (isLoading || isFetching || isDebouncing) && search !== "",
        [isLoading, isFetching, isDebouncing, search]
    );

    const handleKeyDownEnter = () => {
        setOpenedSearchMenu(false);
        setSearch("");
    };

    const handleSearchResultItem = () => {
        setOpenedSearchMenu(false);
    };

    return (
        <SearchMenu opened={openedSearchMenu} onChange={setOpenedSearchMenu}>
            <Stack w="100%">
                <Box mb={30}>
                    <SearchBar
                        search={search}
                        setSearch={setSearch}
                        menu
                        isLoading={isSearching}
                        onKeyDownEnter={handleKeyDownEnter}
                    />
                </Box>
                <Collapse in={data.pagination.total > 0}>
                    <QuickSearchResults
                        count={data.pagination.total}
                        items={data?.data}
                        onClickItem={handleSearchResultItem}
                    />
                    <Menu.Item closeMenuOnClick p={0} w="fit-content" mt={30}>
                        <ResultsLink search={search} />
                    </Menu.Item>
                </Collapse>
                <SearchInfo
                    isShortSearchValue={(trimmedValue?.length ?? 0) <= MINIMUM_LENGTH_TO_SEARCH}
                    isSearching={isSearching}
                    isNotFound={data.pagination.total === 0 && !isLoading && !isFetching && !isDebouncing}
                />
            </Stack>
        </SearchMenu>
    );
}
