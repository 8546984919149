import { Carousel } from "@mantine/carousel";
import { CategoriesData } from "shared/api/categories/types";
import { HoverMenu } from "shared/components/HoverMenu";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";

interface Props {
    category: CategoriesData;
}

export const prepareCategory = (item: CategoriesData) => {
    if (item.subcategory.length > 0) {
        return (
            <HoverMenu
                targetHref={`/catalog/${item.slug}`}
                label={item.name}
                options={item.subcategory.map((item) => ({
                    href: {
                        pathname: "/catalog/[categoryId]",
                        query: {
                            categoryId: String(item.slug ?? item.id),
                        },
                    },
                    id: String(item.id),
                    text: item.name,
                }))}
                tag="mCaps"
                key={item.id}
                withArrow
            />
        );
    }
    return (
        <StyledLink
            href={{
                pathname: "/catalog/[categoryId]",
                query: {
                    categoryId: String(item.slug ?? item.id),
                },
            }}
            key={item.id}>
            {/* px={14 необходимо для подчеркивания категории при ховере как у клиента} */}
            <Typography tag="mCaps" noWrap>
                {item.name}
            </Typography>
        </StyledLink>
    );
};

export function CategorySlide({ category }: Props) {
    return <Carousel.Slide>{prepareCategory(category)}</Carousel.Slide>;
}
