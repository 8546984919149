import { IconComponent } from "public/icons/types";

export const IconMagnifyingGlass: IconComponent = ({ size = 20, color = "inherit", ...props }) => (
    <svg width={size} height={size} color={color} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.66663 9.07438C2.66663 5.53568 5.53531 2.66699 9.07402 2.66699C12.6127 2.66699 15.4814 5.53568 15.4814 9.07438C15.4814 12.6131 12.6127 15.4818 9.07402 15.4818C5.53531 15.4818 2.66663 12.6131 2.66663 9.07438ZM9.07402 0.666992C4.43074 0.666992 0.666626 4.43111 0.666626 9.07438C0.666626 13.7177 4.43074 17.4818 9.07402 17.4818C11.0353 17.4818 12.8397 16.8102 14.2699 15.6845L17.6262 19.0408C18.0167 19.4313 18.6499 19.4313 19.0404 19.0408C19.431 18.6503 19.431 18.0171 19.0404 17.6266L15.6841 14.2703C16.8098 12.8401 17.4814 11.0357 17.4814 9.07438C17.4814 4.43111 13.7173 0.666992 9.07402 0.666992Z"
            fill="currentColor"
        />
    </svg>
);
