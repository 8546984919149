import { Box, Flex, Stack, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IFooterMenuGroup } from "entities/footer/types";
import { IconCircleDown } from "public/icons/IconCircleDown";
import { IconCircleUp } from "public/icons/IconCircleUp";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";
import { useLargerThanMedia } from "shared/utils/mediaQuery";

type Props = IFooterMenuGroup;

function FooterMenuGroup({ title, content }: Props): JSX.Element {
    const [isOpen, { toggle }] = useDisclosure(false);
    const isLargerThanMd = useLargerThanMedia("md", true);

    return (
        <Box w="100%">
            <UnstyledButton onClick={toggle} w="100%">
                <Flex w="100%" justify="space-between">
                    <Typography tag="mCaps" sx={{ cursor: "pointer" }}>
                        {title}
                    </Typography>
                    {!isLargerThanMd && (isOpen ? <IconCircleUp /> : <IconCircleDown />)}
                </Flex>
            </UnstyledButton>
            <Box>
                {(isOpen || isLargerThanMd) && (
                    <Stack mt={15} spacing={5} align="flex-start">
                        {content.map((item, index) => {
                            return item.href ? (
                                <StyledLink
                                    key={`${item.name}_${index}`}
                                    href={item.href}
                                    isExternalLink={item.isExternalLink}>
                                    <Typography
                                        tag="mSemibold"
                                        sx={{
                                            ":hover": {
                                                fontWeight: "bold",
                                            },
                                        }}>
                                        {item.name}
                                    </Typography>
                                </StyledLink>
                            ) : (
                                <UnstyledButton key={`${item.name}_${index}`} onClick={item.onClick}>
                                    <Typography
                                        tag="mSemibold"
                                        sx={{
                                            ":hover": {
                                                fontWeight: "bold",
                                            },
                                        }}>
                                        {item.name}
                                    </Typography>
                                </UnstyledButton>
                            );
                        })}
                    </Stack>
                )}
            </Box>
        </Box>
    );
}

export default FooterMenuGroup;
