import { createStyles } from "@mantine/core";

export const useBasketCartListStyles = createStyles(() => ({
    root: {
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        rowGap: 10,
        columnGap: 15,
    },
}));
