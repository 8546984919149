import { Footer as MantineFooter, MediaQuery } from "@mantine/core";
import { FooterContent } from "features/header/ui/components/FooterContent";

function Footer(): JSX.Element {
    return (
        <MediaQuery query="print" styles={{ display: "none" }}>
            <MantineFooter
                pt={40}
                pb={40}
                height="auto"
                sx={[
                    (theme) => ({
                        zIndex: "auto",
                        borderTop: `2px solid ${theme.colors.main_Black[0]}`,
                    }),
                ]}>
                <FooterContent />
            </MantineFooter>
        </MediaQuery>
    );
}

export default Footer;
