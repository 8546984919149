import { Box, Group } from "@mantine/core";
import { useRouter } from "next/router";
import { SlugStaticPage } from "shared/api/pages/types";
import Typography from "shared/ui/Typography/Typography";

function PersonalDataAccess(): JSX.Element {
    const router = useRouter();
    const handleClickOnAgreement = (): void => {
        router.push({ pathname: "/page/[slug]", query: { slug: SlugStaticPage.CONSENT_TO_PERSONAL_DATA_PROCESSING } });
    };
    const handleClickOnPolicy = (): void => {
        router.push({ pathname: "/page/[slug]", query: { slug: SlugStaticPage.PERSONAL_DATA_PROCESSING_POLICY } });
    };

    return (
        <Group spacing={4}>
            <Box
                onClick={handleClickOnAgreement}
                sx={{
                    cursor: "pointer",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                }}>
                <Typography tag="osSmallRegular">Согласие на обработку персональных данных</Typography>
            </Box>
            <Typography tag="osSmallRegular">|</Typography>
            <Box
                onClick={handleClickOnPolicy}
                sx={{
                    cursor: "pointer",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                }}>
                <Typography tag="osSmallRegular">Политика обработки персональных данных</Typography>
            </Box>
        </Group>
    );
}

export default PersonalDataAccess;
