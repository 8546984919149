import React from "react";
import { Form as FormikForm, Formik, FormikConfig, FormikProps, FormikValues } from "formik";
import { genericMemo } from "shared/utils";

interface FormProps<T = FormikValues> extends FormikConfig<T> {
    className?: string;
    handleSubmit?: FormikProps<T>["handleSubmit"];
}

export const Form = genericMemo(function Form<T extends FormikValues = FormikValues>(props: FormProps<T>) {
    const { className, children, handleSubmit, ...formikConfig } = props;
    return (
        <Formik<T> {...formikConfig}>
            {(props) => (
                <FormikForm
                    className={className}
                    noValidate
                    onClick={(e) => {
                        if (handleSubmit) {
                            handleSubmit(e);
                        }
                    }}>
                    {typeof children === "function" ? children(props) : children}
                </FormikForm>
            )}
        </Formik>
    );
});
