import { memo, ReactNode } from "react";
import { BaseButton, BaseButtonProps } from "shared/ui/Button/BaseButton";
import { useButtonStyles } from "shared/ui/Button/StyledButton/styles";
import Typography, { TypographyProps } from "shared/ui/Typography/Typography";

export type ButtonVariant = "filled" | "outline" | "light" | "white";

export interface StyledButtonProps<IsExternalLink extends boolean | undefined = false>
    extends Omit<BaseButtonProps<IsExternalLink>, "size" | "color"> {
    label?: string | ReactNode;
    variant?: ButtonVariant;
    tag?: TypographyProps["tag"];
}

export const StyledButton = memo(function StyledButton<IsExternalLink extends boolean | undefined = false>(
    props: StyledButtonProps<IsExternalLink>
): JSX.Element {
    const { variant = "filled", label, tag = "buttonCaps", ...restProps } = props;
    const isIconOnly: boolean = (!!restProps.leftIcon || !!restProps.rightIcon) && !label;
    const { classes } = useButtonStyles({ isIconOnly, variant });

    return (
        <BaseButton
            classNames={classes}
            variant={variant}
            size="md"
            disabled={restProps.disabled || restProps.loading}
            {...restProps}>
            {typeof label === "string" ? (
                <Typography tag={tag} color="inherit">
                    {label}
                </Typography>
            ) : (
                label
            )}
        </BaseButton>
    );
});
