import { Flex, Grid } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { useFeedbackCreate } from "features/footer/ui/FeedbackModal/api/hooks";
import { $FeedbackCreate, FeedbackCreate } from "features/footer/ui/FeedbackModal/api/types";
import { FormikConfig } from "formik";
import { IconBuildings } from "public/icons/IconBuildings";
import { IconEnvelopeSimple } from "public/icons/IconEnvelopeSimple";
import { IconPhone } from "public/icons/IconPhone";
import { IconUserCircle } from "public/icons/IconUserCircle";
import { Form } from "shared/ui/Form";
import { Button } from "shared/ui/Forms/Styled";
import { FInput } from "shared/ui/Input/FInput";
import { openDefaultModal } from "shared/ui/ModalProvider/ModalProvider";
import FTextarea from "shared/ui/Textarea/FTextarea";
import Typography from "shared/ui/Typography/Typography";
import { syncErrors } from "shared/utils";
import { toFormikValidationSchema } from "zod-formik-adapter";

function FeedbackModal(): JSX.Element {
    const { mutate, isLoading } = useFeedbackCreate();
    const cfg: FormikConfig<FeedbackCreate> = {
        initialValues: {
            name: "",
            email: "",
            phone: "",
            company: "",
            message: "",
        },
        validationSchema: toFormikValidationSchema($FeedbackCreate),
        onSubmit: (values, formikHelpers) => {
            mutate(values, {
                onError: (error) => syncErrors(error, formikHelpers),
                onSuccess: () => closeAllModals(),
            });
        },
    };
    return (
        <Form {...cfg}>
            <Typography tag="osRegular" color="main_MainDarkGray">
                Нам важно Ваше мнение о качестве нашей работы. Если у Вас есть предложения, пожелания или Вы остались
                недовольны качеством обслуживания, воспользуйтесь формой, приведенной ниже.
            </Typography>

            <Grid grow gutter={15} mt={30}>
                <Grid.Col span={6}>
                    <FInput name="name" icon={<IconUserCircle />} label="Имя и Фамилия" withAsterisk />
                </Grid.Col>
                <Grid.Col span={6}>
                    <FInput name="email" icon={<IconEnvelopeSimple size={20} />} label="E-mail" withAsterisk />
                </Grid.Col>
                <Grid.Col span={6}>
                    <FInput type="mask" name="phone" icon={<IconPhone size={20} />} label="Телефон" withAsterisk />
                </Grid.Col>
                <Grid.Col span={6}>
                    <FInput name="company" icon={<IconBuildings size={20} />} label="Компания" withAsterisk />
                </Grid.Col>
                <Grid.Col span={6}>
                    <FTextarea name="message" label="Сообщение" h={150} withAsterisk />
                </Grid.Col>
            </Grid>
            <Flex gap={15} mt={50}>
                <Button label="Отправить" variant="filled" type="submit" loading={isLoading} />
                <Button label="Отменить" variant="light" onClick={() => closeAllModals()} />
            </Flex>
        </Form>
    );
}

export const openFeedbackModal = () => {
    return openDefaultModal(
        {
            title: "Написать директору",
            children: <FeedbackModal />,
        },
        {
            wide: true,
        }
    );
};
export default FeedbackModal;
