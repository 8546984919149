import { useEffect, useRef, useState } from "react";

export default function useDebounce<T>(value: T, delay = 300): { value: T | undefined; isDebouncing: boolean } {
    const [debouncedValue, setDebouncedValue] = useState<T | undefined>();
    const [isDebouncing, setDebouncing] = useState<boolean>(false);

    const previousValue = useRef<T | undefined>(value);

    useEffect(() => {
        if (JSON.stringify(previousValue.current) === JSON.stringify(value)) {
            return;
        }

        setDebouncing(true);
        const handler = setTimeout(() => {
            setDebouncing(false);
            setDebouncedValue(value);
            previousValue.current = value;
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return { value: debouncedValue, isDebouncing: !!isDebouncing };
}
