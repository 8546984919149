import React from "react";

interface Props {
    isPasswordVisible: boolean;
    toggleVisibility: () => void;
}

export const usePassword = (isPasswordField: boolean): Props => {
    const [isPasswordVisible, setPasswordVisible] = React.useState(isPasswordField);
    const toggleVisibility = () => {
        setPasswordVisible((prev) => !prev);
    };

    return { isPasswordVisible, toggleVisibility };
};
