import { createStyles, CSSObject } from "@mantine/core";
import { BaseSelectStylesNames } from "@mantine/core/lib/Select/types";
import { montserrat } from "providers/Theme/fonts";
import { CardShadow } from "shared/styles/boxShadow";

export const useStyles = createStyles(
    (theme, _, getRef): Partial<Record<BaseSelectStylesNames, CSSObject>> => ({
        root: {
            [`&.mantine-Select-open .${getRef("rightSection")}`]: {
                transform: "rotate(180deg)",
            },
            // [`&.mantine-Select-empty .${getRef("rightSection")}`]: {
            //     display: "none",
            // },
        },
        wrapper: {
            color: theme.colors.main_Black[0],
            ":hover": {
                color: theme.colors.main_MainDarkGray[0],
            },
        },
        input: {
            fontFamily: montserrat.style.fontFamily,
            fontWeight: 600,
            fontSize: 14,
            lineHeight: "20px",
            minHeight: 20,
            maxHeight: "max-content",
            height: 20,
            paddingLeft: 0,
            paddingRight: 6 + 20,
            border: 0,
            overflow: "hidden",
            textOverflow: "ellipsis !important",
            color: "inherit",
            "::placeholder": {
                fontFamily: montserrat.style.fontFamily,
                fontWeight: 600,
                fontSize: 14,
                lineHeight: "20px",
                color: "inherit",
            },
        },
        invalid: {
            color: theme.colors.validation_red[0],
            "::placeholder": {
                color: theme.colors.validation_red[0],
            },
        },
        withIcon: {
            paddingLeft: 20 + 6,
        },
        icon: {
            width: 20,
            color: "inherit",
        },
        rightSection: {
            ref: getRef("rightSection"),
            width: 20,
            color: "inherit",
            transition: "transform 0.3s",
            pointerEvents: "none",
        },
        dropdown: {
            border: 0,
            borderRadius: 2,
            ...CardShadow,
        },
        itemsWrapper: {
            padding: 0,
            paddingTop: 6,
            paddingBottom: 6,
        },
        item: {
            padding: "6px 12px",
            borderRadius: 2,
            backgroundColor: "transparent",
            ":hover": {
                backgroundColor: theme.colors.second_SecondLightGray[0],
            },
            "&[data-selected]": {
                backgroundColor: "transparent",
                color: "inherit",
                ":hover": {
                    backgroundColor: theme.colors.second_SecondLightGray[0],
                },
            },
        },
    })
);
