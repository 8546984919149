import { Box, Tooltip } from "@mantine/core";
import { HTMLReactParserOptions } from "html-react-parser";
import { useTooltipStyles } from "shared/ui/Input/StyledInput/styles";

export const useOptions = (): HTMLReactParserOptions => {
    const { classes: classesTooltip } = useTooltipStyles();

    return {
        transform: (reactNode) => {
            if (typeof reactNode === "string") {
                return reactNode;
            }

            if (reactNode.props.className === "tooltip") {
                const label =
                    (
                        reactNode.props.children?.find((child: JSX.Element | string) => {
                            return typeof child !== "string" && child.props.className === "tooltiptext";
                        }) as JSX.Element
                    )?.props.children || "";

                return (
                    <Tooltip
                        key={reactNode.key}
                        classNames={classesTooltip}
                        withArrow
                        arrowSize={10}
                        position="right-start"
                        label={label}
                        withinPortal>
                        <Box
                            component="span"
                            sx={(theme) => ({
                                ":after": {
                                    verticalAlign: "text-top",
                                    content: '"?"',
                                    display: "inline-block",
                                    textAlign: "center",
                                    width: 20,
                                    height: 20,
                                    border: "1px solid #000",
                                    color: theme.colors.second_white[0],
                                    padding: 0,
                                    backgroundColor: "#000",
                                    borderRadius: "50%",
                                    fontSize: 12,
                                    lineHeight: "17px",
                                    fontWeight: 600,
                                },
                            })}
                        />
                    </Tooltip>
                );
            }

            return reactNode;
        },
    };
};
