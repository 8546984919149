import dynamic from "next/dynamic";
import { openDefaultModal } from "shared/ui/ModalProvider/ModalProvider";

const ThanksForSubscribe = dynamic(() => import("./ThanksForSubscribe"), { loading: () => <>Спасибо за подписку</> });

export const openThanksForSubscribeModal = (email: string) => {
    return openDefaultModal({
        title: "Спасибо за подписку",
        children: <ThanksForSubscribe email={email} />,
    });
};
export default ThanksForSubscribe;
