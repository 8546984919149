import { memo } from "react";
import { Button, ButtonProps } from "@mantine/core";
import { BaseLink } from "shared/ui/Link";
import { BaseLinkProps } from "shared/ui/Link/Base";

export interface BaseButtonProps<IsExternalLink extends boolean | undefined = false>
    extends ButtonProps,
        Pick<BaseLinkProps<IsExternalLink>, "isExternalLink"> {
    className?: string;
    isExternalLink?: IsExternalLink;
    /**
     * Внешняя ссылка должна быть string, начинающейся с протокола (например https://), или URL объектом
     */
    href?: BaseLinkProps<IsExternalLink>["href"];
    download?: string | boolean;
    onClick?: () => void;
}

export const BaseButton = memo(function BaseButton<IsExternalLink extends boolean | undefined = false>(
    props: BaseButtonProps<IsExternalLink>
): JSX.Element {
    const { children, href, isExternalLink, ...restProps } = props;

    if (href) {
        return (
            <Button component={BaseLink} href={href} isExternalLink={isExternalLink} {...restProps}>
                {children}
            </Button>
        );
    }

    return <Button {...restProps}>{children}</Button>;
});
